import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { translationChunksConfig, translations } from '@spartacus/assets';
import { OccConfig, provideConfig } from '@spartacus/core';
import {
  defaultCmsContentProviders,
  layoutConfig,
  mediaConfig
} from '@spartacus/storefront';
import { environment } from 'src/environments/environment';

const occConfig: OccConfig = { backend: { occ: {} } };
// only provide the `occ.baseUrl` key if it is explicitly configured, otherwise the value of
// <meta name="occ-backend-base-url" > is ignored.
// This in turn breaks the deployment in CCv2.
// https://github.com/SAP/spartacus/issues/5886
occConfig.backend.occ.prefix = '/occ/v2/';
if (environment.occBaseUrl) {
  occConfig.backend.occ.baseUrl = environment.occBaseUrl;
}

const PRODUCT_TYPE = 'DEFAULT';

@NgModule({
  declarations: [],
  imports: [BrowserModule],
  providers: [
    provideConfig(layoutConfig),
    provideConfig(mediaConfig),
    ...defaultCmsContentProviders,
    provideConfig({
      backend: occConfig.backend,
      smartEdit: {
        storefrontPreviewRoute: 'cx-preview',
        // TODO: adjust origins as necessary
        allowOrigin: 'localhost:9002, *.*.model-t.cc.commerce.ondemand.com:443, *.pharma.aventis.com:443',
      },
      context: {
        urlParameters: ['baseSite', 'language', 'currency'],
        baseSite: ['dz'],
        currency: ['DZD'],
        language: ['fr_DZ','en'],
      },
      i18n: {
        resources: translations,
        chunks: translationChunksConfig,
        fallbackLang: 'fr',
      },
      features: {
        level: '6.0',
      },
    }),
    provideConfig({
      backend: {
        occ: {
          endpoints: {
            asmCustomerSearch: '/occ/v2/dz/assisted-service/customers/search',
          },
        },
      },
    } as OccConfig),
  ],
})
export class SpartacusConfigurationModule {}
