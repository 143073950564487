<div class="acc-overviewtabs">
  <div class="row table-sanofi">
    <div class="col-md-3">
      <h3 class="financial">
        {{ "accountOverview.Financials.label" | cxTranslate }}
      </h3>
    </div>
    <div class="col-md-9 nav-alignment">
      <div class="prodCard-tabs tab_right_float">
        <ul class="prodCard-list">
          <li class="tab-link" [ngClass]="{ active: selectedTab === 'INVOICES' }" data-tab="tab_1"
            (click)="tabNavigation('INVOICES')">
            {{ "accountOverview.Financials.invoice_label" | cxTranslate }}
          </li>

          <li class="tab-link" [ngClass]="{ active: selectedTab === 'CREDITS' }" data-tab="tab_3"
            (click)="tabNavigation('CREDITS')">
            {{ "accountOverview.Financials.credits_label" | cxTranslate }}
          </li>
          <li class="tab-link" [ngClass]="{ active: selectedTab === 'STATEMENTS' }" data-tab="tab_4"
            (click)="tabNavigation('STATEMENTS')">
            {{ "accountOverview.Financials.statements_label" | cxTranslate }}
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div id="tab_1" class="tab_content current">
    <app-loader *ngIf="!isLoadingDone && selectedTab !== 'STATEMENTS'"></app-loader>
    <div class="text-center no-invoices" *ngIf="
        !!paymentList &&
        paymentList.length === 0 &&
        isLoadingDone &&
        selectedTab === 'INVOICES'
      ">
      {{ "accountOverview.Financials.no_invoice_found" | cxTranslate }}
    </div>
    <div class="text-center no-credits" *ngIf="
        !!creditList &&
        creditList.length === 0 &&
        isLoadingDone &&
        selectedTab === 'CREDITS'
      ">
      {{ "accountOverview.Financials.no_credit_found" | cxTranslate }}
    </div>
    <div class="row xls-pdf-invoice" *ngIf="showXlsPdf && isLoadingDone && selectedTab !== 'STATEMENTS'">
      <div class="col-md-12 ad-files-text darkblue full-wd">
        <a title="XLS" id="invoiceXLS" aria-label="download account overview invoices as" data-di-id="#invoiceXLS">
          <img src="../../assets/icons/excel.png" alt="" class="ad-pdf" target="_blank" />
          <span class="acc-icon-text" (click)="generateExcel()">{{
            "accountOverview.Financials.excel_label" | cxTranslate
            }}</span></a>
        <a id="invoicePDF" aria-label="download account overview invoices as" data-di-id="#invoicePDF">
          <img src="../../assets/icons/pdf.svg" alt="PDF" class="ad-pdf" />
          <span class="acc-icon-text" (click)="generatePDF()">{{
            "accountOverview.Financials.pdf_label" | cxTranslate
            }}</span>
        </a>
      </div>
    </div>
    <section class="row">
      <section class="col-md-12 col-xs-12 col-sm-12 fin-Table-Pad" id="invoice-table">
        <section class="payment-section" *ngIf="
            !!paymentList &&
            paymentList.length > 0 &&
            isLoadingDone &&
            selectedTab === 'INVOICES'
          ">
          <table aria-describedby="invoicesTable" id="applyCreditsTable" class="payments-table table table-striped"
            [ngClass]="{ 'not-pdf': isPDF === false }">
            <thead>
              <tr>
                <th scope="col" id="docDate" (click)="sortColumn($event, 'docDate', paymentList, 'Date')">
                  {{
                  "accountOverview.Financials.invoices.date_label"
                  | cxTranslate
                  }}
                  &nbsp;
                  <em class="my-icon fas fa-caret-down" *ngIf="!isPDF"></em>
                </th>
                <th scope="col" id="docNo" (click)="sortColumn($event, 'docNo', paymentList, 'normal')">
                  {{
                  "accountOverview.Financials.invoices.invoice_no"
                  | cxTranslate
                  }}
                  &nbsp;
                  <em class="my-icon fas fa-caret-down" *ngIf="!isPDF"></em>
                </th>
                <th scope="col" id="dueDate" (click)="sortColumn($event, 'dueDate', paymentList, 'Date')">
                  {{
                  "accountOverview.Financials.invoices.due_date" | cxTranslate
                  }}
                  &nbsp;
                  <em class="my-icon fas fa-caret-down" *ngIf="!isPDF"></em>
                </th>
                <th scope="col" class="text-right" id="amountDue"
                  (click)="sortColumn($event, 'amount', paymentList, 'Number')">
                  {{
                  "accountOverview.Financials.invoices.amount_due"
                  | cxTranslate
                  }}
                  &nbsp;
                  <em class="my-icon fas fa-caret-down" *ngIf="!isPDF"></em>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of paymentList; let i = index">
                <td class="pay">{{ data?.docDate }}</td>
                <td class="pay">
                  <a *ngIf="
                      data?.docType === 'RV' ||
                      data?.docType === 'RD' ||
                      data?.docType === 'RF'">
                    {{ data.refDocNo }}
                  </a>
                  <a *ngIf="
                      data?.docType === 'DZ' && data?.dochdrtxt?.startsWith('7')">
                    {{ data.dochdrtxt }}
                  </a>
                  <span *ngIf="
                      data?.docType === 'DR' ||
                      data?.docType === 'DG' ||
                      data?.docType === 'DY'
                    ">
                    {{ data.refDocNo }}
                  </span>
                  <span *ngIf="
                      data?.docType === 'DZ' &&
                      !data?.dochdrtxt?.startsWith('7')
                    ">
                    {{ data.dochdrtxt }}
                  </span>
                  <span *ngIf="data?.docType === 'YR'">
                    {{ data.refhdr }}
                  </span>
                </td>
                <td class="pay">
                  <span [ngClass]="{
                      red: !!data?.dueDate && dateComparison(data?.dueDate)
                    }">{{ data?.dueDate }}
                    <ng-template #popSaveContent>
                      <span class="popover-content">{{
                        "makeAPayment.past_due_date" | cxTranslate
                        }}</span>
                    </ng-template>
                    <span [ngbPopover]="popSaveContent" triggers="manual" #p1="ngbPopover" placement="top"
                      (click)="toggle(p1)" container="body" popoverClass="popover-links-invoice"
                      *ngIf="!!data?.dueDate && dateComparison(data?.dueDate)">
                      <img src="assets/icons/warning_red.png" alt="warningIcon" class="warningIconEvent" />
                    </span>
                  </span>
                </td>
                <td class="pay inv-text-end amount-Wrap">
                  {{ data?.amount | localCurrency }}
                </td>
              </tr>
            </tbody>
          </table>
          <table aria-describedby="invoicesTable" id="applyInvoiceRWD" class="payments-table table table-striped"
            [ngClass]="{ 'not-pdf': isPDF === false }">
            <tr class="sample-header">
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
            <tr *ngFor="let data of paymentList; let i = index">
              <td class="two-column-lay header">
                {{
                "accountOverview.Financials.invoices.date_label" | cxTranslate
                }}
              </td>
              <td class="two-column-lay">{{ data?.docDate }}</td>
              <td class="two-column-lay header">
                {{
                "accountOverview.Financials.invoices.invoice_no" | cxTranslate
                }}
              </td>
              <td class="two-column-lay">
                <a *ngIf="
                    data?.docType === 'RV' ||
                    data?.docType === 'RD' ||
                    data?.docType === 'RF'">
                  {{ data.refDocNo }}
                </a>
                <a *ngIf="
                    data?.docType === 'DZ' && data?.dochdrtxt?.startsWith('7')">
                  {{ data.dochdrtxt }}
                </a>
                <span *ngIf="
                    data?.docType === 'DR' ||
                    data?.docType === 'DG' ||
                    data?.docType === 'DY'
                  ">
                  {{ data.refDocNo }}
                </span>
                <span *ngIf="
                    data?.docType === 'DZ' && !data?.dochdrtxt?.startsWith('7')
                  ">
                  {{ data.dochdrtxt }}
                </span>
                <span *ngIf="data?.docType === 'YR'">
                  {{ data.refhdr }}
                </span>
              </td>
              <td class="two-column-lay header">
                {{
                "accountOverview.Financials.invoices.due_date" | cxTranslate
                }}
              </td>
              <td class="two-column-lay">
                <span [ngClass]="{
                    red: !!data?.dueDate && dateComparison(data?.dueDate)
                  }">{{ data?.dueDate }}
                  <ng-template #popSaveContent>
                    <span class="popover-content">{{
                      "makeAPayment.past_due_date" | cxTranslate
                      }}</span>
                  </ng-template>
                  <span [ngbPopover]="popSaveContent" triggers="manual" #p1="ngbPopover" placement="top"
                    (click)="toggle(p1)" container="body" popoverClass="popover-links-invoice"
                    *ngIf="!!data?.dueDate && dateComparison(data?.dueDate)">
                    <img src="assets/icons/warning_red.png" alt="warningIcon" class="warningIconEvent" />
                  </span>
                </span>
              </td>
              <td class="two-column-lay header">
                {{
                "accountOverview.Financials.invoices.amount_due" | cxTranslate
                }}
              </td>
              <td class="two-column-lay">{{ data?.amount | localCurrency }}</td>
            </tr>
          </table>
        </section>
      </section>
    </section>
    <section id="pdf-dummy" *ngIf="isPDF"></section>

    <section *ngIf="
        !!creditList &&
        creditList.length > 0 &&
        isLoadingDone &&
        selectedTab === 'CREDITS'
      " id="credits-table" class="credits-section">
      <table aria-describedby="creditsTable" id="applyCreditsTable" class="credits-table table table-striped"
        [ngClass]="{ 'not-pdf': isPDF === false }">
        <thead>
          <tr>
            <th scope="col" (click)="sortColumn($event, 'docDate', creditList, 'Date')" id="creditDate">
              {{ "makeAPayment.credit_date" | cxTranslate }} &nbsp;
              <em class="my-icon fas fa-caret-down" *ngIf="!isPDF"></em>
            </th>
            <th scope="col" id="creditNo" (click)="sortColumn($event, 'docNo', creditList, 'normal')">
              {{ "makeAPayment.credit#" | cxTranslate }}&nbsp;
              <em class="my-icon fas fa-caret-down" *ngIf="!isPDF"></em>
            </th>
            <th scope="col" class="text-left" id="creditAmt"
              (click)="sortColumn($event, 'amount', creditList, 'Number')">
              {{ "makeAPayment.credit_amount" | cxTranslate }}
              <em class="my-icon fas fa-caret-down" *ngIf="!isPDF"></em>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of creditList; let i = index">
            <td>
              {{ data?.docDate }}
            </td>
            <td>
              <a *ngIf="
                  data?.docType === 'RV' ||
                  data?.docType === 'RD' ||
                  data?.docType === 'RF'">
                {{ data.refDocNo }}
              </a>
              <a *ngIf="
                  data?.docType === 'DZ' && data?.dochdrtxt?.startsWith('7')">
                {{ data.dochdrtxt }}
              </a>
              <span *ngIf="
                  data?.docType === 'DR' ||
                  data?.docType === 'DG' ||
                  data?.docType === 'DY'
                ">
                {{ data.refDocNo }}
              </span>
              <span *ngIf="
                  data?.docType === 'DZ' && !data?.dochdrtxt?.startsWith('7')
                ">
                {{ data.dochdrtxt }}
              </span>
              <span *ngIf="data?.docType === 'YR'">
                {{ data.refhdr }}
              </span>
            </td>
            <td class="text-left">{{ data?.amount | localCurrency }}</td>
          </tr>
        </tbody>
      </table>
      <table aria-describedby="creditsTable" id="applyCreditsRWD" class="credits-table table table-striped"
        [ngClass]="{ 'not-pdf': isPDF === false }">
        <tr class="sample-header">
          <th scope="col"></th>
          <th scope="col"></th>
        </tr>
        <tr *ngFor="let data of creditList; let i = index">
          <td class="two-column-lay header">
            {{ "makeAPayment.credit_date" | cxTranslate }}
          </td>
          <td class="two-column-lay">{{ data?.docDate }}</td>
          <td class="two-column-lay header">
            {{ "makeAPayment.credit#" | cxTranslate }}
          </td>
          <td class="two-column-lay">
            <a *ngIf="
                data?.docType === 'RV' ||
                data?.docType === 'RD' ||
                data?.docType === 'RF'">
              {{ data.refDocNo }}
            </a>
            <a *ngIf="data?.docType === 'DZ' && data?.dochdrtxt?.startsWith('7')">
              {{ data.dochdrtxt }}
            </a>
            <span *ngIf="
                data?.docType === 'DR' ||
                data?.docType === 'DG' ||
                data?.docType === 'DY'
              ">
              {{ data.refDocNo }}
            </span>
            <span *ngIf="
                data?.docType === 'DZ' && !data?.dochdrtxt?.startsWith('7')
              ">
              {{ data.dochdrtxt }}
            </span>
            <span *ngIf="data?.docType === 'YR'">
              {{ data.refhdr }}
            </span>
          </td>
          <td class="two-column-lay header">
            {{ "makeAPayment.credit_amount" | cxTranslate }}
          </td>
          <td class="two-column-lay text-left">
            {{ data?.amount | localCurrency
          </td>
        </tr>
      </table>
    </section>
    <section *ngIf="selectedTab === 'STATEMENTS'">
      <app-open-statements [hideAside]="removeAside"></app-open-statements>
    </section>
    <div class="invoice-btngrps" *ngIf="
        !!paymentList &&
        paymentList.length > 0 &&
        isLoadingDone &&
        selectedTab === 'INVOICES'
      ">
    </div>
    <div class="invoice-btngrps" *ngIf="
        !!creditList &&
        creditList.length > 0 &&
        isLoadingDone &&
        selectedTab === 'CREDITS'
      ">
      <button type="button" class="btn btn-primary oi-blue-button btnpaymentinvoicecredit"
        data-di-id="di-id-41a05d18-6b04a424" [routerLink]="['/my-account/invoices']">
        {{ "accountOverview.Financials.credits.credit_btn_txt" | cxTranslate }}
      </button>
    </div>
    <div class="invoice-btngrps" *ngIf="selectedTab === 'STATEMENTS' && !!isLoadingDone">
      <button type="button" class="btn btn-primary statement oi-blue-button btnpaymentinvoicecredit"
        data-di-id="di-id-41a05d18-6b04a424" [routerLink]="['/my-account/statements']">
        {{
        "accountOverview.Financials.statements.statement_btn_txt"
        | cxTranslate
        }}
      </button>
    </div>
  </div>
</div>
<ng-template #invoicePastDueDateMsg>{{
  "accountOverview.Financials.pdf_label" | cxTranslate
  }}</ng-template>
<div class="hide-section" *ngIf="isPDF">
  <div class="col-md-12" id="pdf-header">
    <h3 class="financial">
      {{ "accountOverview.Financials.label" | cxTranslate }}
    </h3>
  </div>
  <div class="col-md-12" id="pdf-subheader">
    <h3 class="sub-label-pdf" *ngIf="selectedTab === 'INVOICES'">
      {{ "accountOverview.Financials.invoice_label" | cxTranslate }}
    </h3>
    <h3 class="sub-label-pdf" *ngIf="selectedTab === 'CREDITS'">
      {{ "accountOverview.Financials.credits_label" | cxTranslate }}
    </h3>
  </div>
  <div class="line-top-sanofi-pdf" id="line-sanofi"></div>
</div>
