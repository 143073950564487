<div *ngIf="showSuccessMessage" class="global-alerts">
  <div class="alert alert-info alert-dismissable getAccAlert">
    <button (click)="closeInfoAlert()" class="close closeAccAlert" aria-hidden="true" data-dismiss="alert"
            type="button" data-di-id="di-id-4f6d7b93-70563360">×
    </button>
    <span class="alert-text">{{"importCart.successMessage" | cxTranslate}}</span>
  </div>
</div>
<div class="" id="leftCont-rightNav">
  <div [innerHtml]="cmsContent | safeHtml"></div>
          <strong *ngIf="numberOfColumnsError" class="import_error_1">{{'importCart.numberOfColumnsError'|cxTranslate}}</strong>
          <strong *ngIf="incorrectColumnsError" class="import_error_2">{{'importCart.incorrectColumnsError'|cxTranslate}}</strong>
          <strong *ngIf="noFileSelectedError" class="import_error_3">{{'importCart.noFileSelectedError'|cxTranslate}}</strong>
          <strong *ngIf="incorrectFileFormatError" class="import_error_4">{{'importCart.incorrectFileFormatError'|cxTranslate}}</strong>
</div>
