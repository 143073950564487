<section class="container footer-Container-Section">
  <section class="row">
    <section class=" col-12 col-sm-12 col-md-5 col-lg-3 col-xl-3 influenza-Contact-Section">
      <section class="row">
        <section class="col-8 col-sm-8 col-md-8 inf-Contact">{{'selectVaccines.influenzaContact'|cxTranslate}}</section>
        <section class="col-3 col-sm-3 col-md-4 iphone-Icon edit-Margin" *ngIf="infContact">
          <img alt="" src="/assets/icons/editIcon.png" class="edit-Icon-Image" *ngIf="editNotClicked">
          <span class="edit-Option" (click)="editContact()" *ngIf="editNotClicked">
            {{'selectVaccines.edit'|cxTranslate}}
          </span>
        </section>
      </section>
      <!--edit is not clicked-->
      <section class="container" *ngIf="editNotClicked && infContact">
        <section class="col-md-12 padd-0 inf-Contact-Desc">{{'selectVaccines.contactMsg'|cxTranslate}}
        </section>
        <section class="col-md-12 res-contact padd-0">
          <ul *ngFor="let con of infContact" class="no-Bullets">
            <li>
              <p class="displayCell">{{'selectVaccines.name'|cxTranslate}}</p>
              <span class="align-Top displayCell" *ngIf="con.firstName">{{con.firstName | titlecase}} {{con.lastName ?
                (con.lastName
                | titlecase) : ''}} </span>
            </li>
            <li>
              <p>{{'selectVaccines.prefer_Contact'|cxTranslate}}</p>
              <a href="{{'mailto:'+con?.email}}"  class="align-Top prefer-Value" *ngIf="con.contactMethod == 'INT'">{{con.email}}</a>
              <span class="align-Top prefer-Value" *ngIf="con.contactMethod == 'FAX'">{{con.fax}}</span>
            </li>
            <li>
              <p>{{'selectVaccines.prefer_Lang'|cxTranslate}}</p>
              <span class="align-Top">{{con.preferredLanguage === 'EN' ? ('selectVaccines.englishLang'|cxTranslate) : ('selectVaccines.frenchLang'|cxTranslate)}}</span>
            </li>
          </ul>
        </section>
      </section>
      <section class="col-12 col-sm-8 col-md-12" *ngIf="editNotClicked && !infContact">
        <button type="button" class="btn btn-default white-button" (click)="editContact()"
          [ngClass]="{'borderRed':reserveNowClicked}">
          {{'selectVaccines.addContact'|cxTranslate}}
        </button>
        <span class="text-Contact" *ngIf="reserveNowClicked">{{'selectVaccines.add_Contact_Err'|cxTranslate}}</span>
      </section>
      <!--edit is clicked-->
      <section class="container" *ngIf="!editNotClicked" [formGroup]="editForm">
        <section class="row">
          <section class="col-md-12 ">
            <label class="edit-labels">{{'selectVaccines.first_name'|cxTranslate}}</label>
          </section>
          <section class="col-md-12">
            <input class="form-control input-Field-Styling" type="text" onkeypress="return /[a-zA-Z0-9-' ]+('[a-zA-Z0-9-])?[-a-zA-Z0-9]*$/i.test(event.key)"
              formControlName="firstName" maxlength="255" [ngClass]="{
                requiredField:
              editForm.get('firstName').invalid &&
                (editForm.get('firstName').touched ||editForm.get('firstName').dirty)
            }" />
            <span class="text-error" *ngIf="
              !editForm.get('firstName').value &&
             editForm.get('firstName').touched
          ">{{'selectVaccines.required_field'|cxTranslate}}</span>
          <span
        class="text-danger"
        *ngIf="
        editForm.get('firstName').value &&
        editForm.get('firstName').invalid &&
          (editForm.get('firstName').touched ||editForm.get('firstName').dirty)
        "
        >{{'selectVaccines.invalidName'| cxTranslate}}</span
      >
          </section>
          <section class="col-md-12 ">
            <label class="edit-labels">{{'selectVaccines.last_name'|cxTranslate}}</label>
          </section>
          <section class="col-md-12">
            <input class="form-control  input-Field-Styling" type="text" onkeypress="return /[a-zA-Z0-9-' ]+('[a-zA-Z0-9-])?[-a-zA-Z0-9]*$/i.test(event.key)"
              formControlName="lastName" maxlength="255" [ngClass]="{
              requiredField:
            editForm.get('lastName').invalid &&
              (editForm.get('lastName').touched ||editForm.get('lastName').dirty)
          }" />
            <span class="text-error" *ngIf="
            !editForm.get('lastName').value &&
           editForm.get('lastName').touched
        ">{{'selectVaccines.required_field'|cxTranslate}}</span>
        <span
        class="text-danger"
        *ngIf="
        editForm.get('lastName').value &&
        editForm.get('lastName').invalid &&
          (editForm.get('lastName').touched ||editForm.get('lastName').dirty)
        "
        >{{'selectVaccines.invalidName'| cxTranslate}}</span
      >
          </section>
          <section class="col-md-12 ">
            <h6 class="edit-labels">{{'selectVaccines.prefer_Contact'|cxTranslate}}</h6>
          </section>
          <section class="col-md-12 ">
            <label for="email" class="edit-email edit-labels">{{'selectVaccines.email'|cxTranslate}}</label>
          </section>
          <section class="row padd-49">
            <input class="col-3 col-sm-3 col-md-1 preferred-Contact" type="radio" name="preferredContact"
              formControlName="preferredContact" value='INT' [checked]="emailChecked" (change) = "onChange($event)" />
            <input class="col-9 col-sm-9 col-md-10 form-control contact-Field input-Field-Styling" type="email"
              formControlName="emailId" maxlength="241" [ngClass]="{
                'requiredField':
              editForm.get('emailId').invalid &&
                (editForm.get('emailId').touched ||editForm.get('emailId').dirty)
            }" />
            <span class="text-error" *ngIf="editForm.get('emailId').invalid && 
           (editForm.get('emailId').dirty || editForm.get('emailId').touched) 
            && editForm.get('emailId').value">
            {{'selectVaccines.validEmail_err'|cxTranslate}}
            </span>
            <span class="text-error" *ngIf="
             editForm.get('preferredContact').value == 'INT'
            && !editForm.get('emailId').value &&  editForm.get('emailId').touched">{{'selectVaccines.required_field'|cxTranslate}}
            </span>
          </section>
          <section class="col-md-12 ">
            <label for="fax" class="edit-labels edit-email">{{'selectVaccines.fax'|cxTranslate}}</label>
          </section>
          <section class="row padd-49">
            <input class="col-3 col-sm-3 col-md-1 preferred-Contact" type="radio" name="preferredContact"
              formControlName="preferredContact" value='FAX'(change) = "onChange($event)" />
            <input class="col-9 col-sm-9 col-md-10 form-control contact-Field  input-Field-Styling" appPhoneMask
              placeholder="xxx-xxx-xxxx" formControlName="faxNo" type="text" maxlength="12"
              onkeypress="return /[0-9-]/i.test(event.key)" [ngClass]="{
                requiredField:
              editForm.get('faxNo').invalid &&
                (editForm.get('faxNo').touched ||editForm.get('faxNo').dirty)
            }" />
            <span class="text-error" *ngIf="editForm.get('faxNo').invalid && 
           (editForm.get('faxNo').dirty || editForm.get('faxNo').touched) 
            && editForm.get('faxNo').value">
            {{'selectVaccines.validFax_err'|cxTranslate}}
            </span>
            <span class="text-error" *ngIf="
            editForm.get('preferredContact').value == 'FAX'  && !editForm.get('faxNo').value && editForm.get('faxNo').touched">
              {{'selectVaccines.required_field'|cxTranslate}}
            </span>
          </section>
          <section class="col-md-12 ">
            <h6 class="edit-labels">{{'selectVaccines.prefer_Lang'|cxTranslate}}</h6>
          </section>
          <section class="col-md-12">
            <ng-select class="w-100" [items]="languages" bindLabel="language" bindValue="language" [multiple]="false"
              formControlName="language" [clearable]="false" placeholder="Select Language" [(ngModel)]="chosenLang">
            </ng-select>
          </section>
          <section class="col-md-12 align-Lang">
            <button (click)="onCancelClick()"
              class="col-5 col-sm-5 col-md-5 btn cancel-Btn">{{'selectVaccines.cancel'|cxTranslate}}</button>
            <button (click)="onSave()"
              class="col-5 col-sm-5 col-md-5 btn save-Btn">{{'selectVaccines.save'|cxTranslate}}</button>
          </section>
        </section>
      </section>
      <!--form end-->
    </section>
    <!--middlesec-->
    <section class="col-12 col-sm-12 col-md-7 col-lg-6 col-xl-6 active-season-Section">
      <section *ngIf ="displayActiveSeasons">
      <section class="row">
        <section class="col-12 col-xs-12 col-md-8 col-xl-9 col-lg-9">
          <section class="season-header">
            {{activeSeasons}} {{'selectVaccines.season'|cxTranslate}}
          </section>
        </section>
        <section class=" col-12 col-xs-12 col-md-4 col-xl-3 col-lg-3">
          <button class="btn Reserve-Btn" (click)="onReserve()">{{'selectVaccines.reserve_now'|cxTranslate}}</button>
        </section>
      </section>
      <!--products-->
      <section class="row row-Gap" *ngFor="let user of productList; let i = index" id="{{user.code}}">
        <section class="col-2 col-sm-1 col-md-1 col-lg-1 col-xl-1">
          <a (click)="productDetails(user)">
          <img *ngIf="user.thumbNailImg !== ''" src="{{baseUrl}}{{user.thumbNailImg}}" class="vaccine-Image" alt="prodImg" />
          <img *ngIf="user.thumbNailImg == ''" [src]="fallbackImage" class="vaccine-Image" alt="defImg" />
          </a>
        </section>
        <section class="col-10 col-sm-10 col-md-8 col-lg-6 col-xl-6 prod-Desc-Div">
          <section class="container">
            <section class="row">

              <section class="col-md-12 col-lg-12 col-xl-12">
                <a (click)="productDetails(user)" title="{{user.name}}" class="vaccine-Name" [innerHTML] = "user.name"></a>
              </section>
              <section class="col-md-12 col-lg-12 col-xl-12 vaccine-Desc-Section" [innerHTML] = "user.description">
              </section>
              <section class="col-md-12 col-lg-12 col-xl-12 prod-No">
                {{'selectVaccines.product_number'|cxTranslate}}: <span class="prodct-No">{{user.code}}</span>
              </section>
            </section>
          </section>
        </section>
        <!-- ============================order acceptance=========================================== -->

        <section class="col-5 col-sm-5 col-md-3 col-xs-6 order-Acceptance">
          <span *ngIf="!user.available && user.discontinued===false" class="">
             {{user?.availabilityText ?  user?.availabilityText : ('selectVaccines.product_unavailable'|cxTranslate) }} 
          </span>
          <span class="" *ngIf="user.discontinued===true">
            {{'selectVaccines.product_discontinued'|cxTranslate}}</span>
            <span class="available-now" *ngIf="user.onsn === 'ON' && user.discontinued===false && user.available===true">
              {{'selectVaccines.available_now'|cxTranslate}}
              </span>
        </section>
      </section>
    </section>
    </section>
  </section>
</section>