<section class="row makePaymentView" id="productList-area">
  <aside class="col-md-3 col-sm-3 col-lg-2 col-xs-12 applied-filter pr-0">
    <fieldset id="filter-by">
      <legend class="filterByTitle-main order-history-search">
        {{ "ordersList.searchBy" | cxTranslate }}
      </legend>
      <div class="grey-line"></div>
      <div class="filterByGroup mb-3">
        <div class="filterByTitle">
          <label for="invoice">{{
            "ordersList.timeFrame" | cxTranslate
            }}</label>
        </div>
        <ng-select name="timeFrame" [items]="timeFrameList" [searchable]="false" [clearable]="false" bindLabel="name"
          bindValue="code" class="dropdown-credit" (ngModelChange)="clearDateRange()" [(ngModel)]="timeFrame">
        </ng-select>
      </div>

      <!--Date Range-->
      <div class="filterByGroup">
        <div class="filterByTitle mb-2">
          {{ "ordersList.dateRange" | cxTranslate }}
        </div>
        <div class="delivary-details">
          <div>
            <fieldset class="form-horizontal mb0">
              <legend class="control-group mb-2">
                <label for="fromDate" aria-labelledby="dataRange fromDate">{{
                  "ordersList.from" | cxTranslate
                  }}</label>
              </legend>
              <div class="headSearch-holder">
                <label class="searchbox orders-filter time-frame">
                  <img src="../../../assets/icons/calendar.svg" alt="" />
                  <input type="text" placeholder="{{ 'ordersList.dateFormat' | cxTranslate }}" class="input-filter"
                    [(ngModel)]="fromDate" (ngModelChange)="validateDate()" bsDatepicker [maxDate]="maxSelectionDate"
                    [daysDisabled]="[0, 6]" [bsConfig]="{ containerClass: 'order-list-date' }" />
                </label>
              </div>
            </fieldset>
          </div>
        </div>
        <div class="delivary-details">
          <div>
            <fieldset class="form-horizontal mb0">
              <legend class="control-group mb-2">
                <label for="toDate" aria-labelledby="dataRange toDate">{{
                  "ordersList.to" | cxTranslate
                  }}</label>
              </legend>
              <div class="headSearch-holder">
                <label class="searchbox orders-filter time-frame">
                  <img src="../../../assets/icons/calendar.svg" alt="" />
                  <input type="text" placeholder="{{ 'ordersList.dateFormat' | cxTranslate }}" class="input-filter"
                    [(ngModel)]="toDate" (ngModelChange)="validateDate()" bsDatepicker [maxDate]="maxSelectionDate"
                    [daysDisabled]="[0, 6]" [bsConfig]="{ containerClass: 'order-list-date' }" />
                </label>
              </div>
            </fieldset>
          </div>
        </div>
        <span class="dateError" *ngIf="dateError">{{
          "ordersList.invalidDateErrMsg" | cxTranslate
          }}</span>
        <span class="dateError" *ngIf="singleDateError">{{
          "ordersList.selectBothDate" | cxTranslate
          }}</span>
      </div>
      <!--End-->

      <div class="filterByGroup">
        <div class="filterByTitle mb-2">
          <label for="invoice">{{ "ordersList.invoice" | cxTranslate }}</label>
        </div>
        <div class="headSearch-holder">
          <label class="searchbox orders-filter time-frame">
            <input placeholder="{{'ordersList.searchInput' | cxTranslate}}" type="text" autocomplete="off"
              aria-describedby="initialDescription" aria-controls="results" aria-autocomplete="both"
              class="input-filter" [(ngModel)]="invoice" />
            <button role="presentation" class="search-icon">
              <cx-icon [type]="iconTypes.SEARCH"></cx-icon>
            </button>
          </label>
        </div>

        <div class="filterByTitle mb-2">
          <label for="poNumber">{{ "ordersList.po" | cxTranslate }}</label>
        </div>
        <div class="headSearch-holder">
          <label class="searchbox orders-filter time-frame">
            <input placeholder="{{'ordersList.searchInput' | cxTranslate}}" type="text" autocomplete="off"
              aria-describedby="initialDescription" aria-controls="results" aria-autocomplete="both"
              class="input-filter" [(ngModel)]="poNumber" />
            <button role="presentation" class="search-icon">
              <cx-icon [type]="iconTypes.SEARCH"></cx-icon>
            </button>
          </label>
        </div>

        <div class="filterByTitle mb-2">
          <label for="orderNumber">{{
            "ordersList.order" | cxTranslate
            }}</label>
        </div>
        <div class="headSearch-holder">
          <label class="searchbox orders-filter time-frame">
            <input placeholder="{{'ordersList.searchInput' | cxTranslate}}" type="text" autocomplete="off"
              aria-describedby="initialDescription" aria-controls="results" aria-autocomplete="both"
              class="input-filter" [(ngModel)]="order" />
            <button role="presentation" class="search-icon">
              <cx-icon [type]="iconTypes.SEARCH"></cx-icon>
            </button>
          </label>
        </div>

        <!-- Order Status -->
        <div class="filterByGroup mb-3">
          <div class="filterByTitle">
            <label for="invoice">{{
              "ordersList.orderStatus" | cxTranslate
              }}</label>
          </div>
          <ng-select name="orderStatus" [items]="statusesList" [searchable]="false" [clearable]="false" bindLabel="name"
            bindValue="code" class="dropdown-credit" [(ngModel)]="statuses">
          </ng-select>
        </div>

      </div>
      <div class="account-order-filter-btn">
        <button type="submit" class="btn btn-default blue-button" data-di-id="di-id-362f2c77-5f23e741"
          (click)="applyFilter()">
          {{ "ordersList.applyFilters" | cxTranslate }}
        </button>
        <button type="button" id="clearOrderFiltersBtn" class="btn btn-default white-button"
          data-di-id="#clearOrderFiltersBtn" (click)="clearFilters()">
          {{ "ordersList.clearFilters" | cxTranslate }}
        </button>
      </div>
    </fieldset>
    <div class="line-intersection"></div>
    <section>
      <div>
        <app-need-to-go-back></app-need-to-go-back>
      </div>
      <div class="line-intersection"></div>
    </section>
    <section>
      <div class="banner-img">
        <app-flu-dashboard-banner bannerComponent="OrderHistoryBannerComponent"></app-flu-dashboard-banner>
      </div>
    </section>
  </aside>
  <section class="col-md-9 col-sm-9 col-lg-10 table-s-section float-right">
    <section class="filter-section">
      <section class="row">
        <section class="col-sm-12 col-md-12 col-md-12 col-xs-12 op_ovice">
          <div id="filter-applied" class="applied-filter">
            <div class="row row-20 page-section">
              <div class="col-md-8 col-sm-7 hidden-xs filterApply-showing">
                <span>{{ "ordersList.showing" | cxTranslate }} {{ end }}
                  {{ "ordersList.of" | cxTranslate }}
                  {{ totalResults }}
                  {{ "ordersList.orders" | cxTranslate }}</span>
              </div>
              <div class="col-md-4 col-sm-5 top-pagination left-pagination">
                <div class="time-frame">
                  <span *ngIf="totalPages != 0" class="top-page-id mr-2 pt-1" id="accPageInfo">{{ "ordersList.page" |
                    cxTranslate }} {{ curPage+1 }}
                    {{ "ordersList.of" | cxTranslate }}
                    {{ totalPages }}</span>
                  <span *ngIf="totalPages == 0" class="top-page-id mr-2 pt-1" id="accPageInfo">{{ "ordersList.page" |
                    cxTranslate }} {{ curPage }}
                    {{ "ordersList.of" | cxTranslate }}
                    {{ totalPages }}</span>
                  <div class="pagination">
                    <button [disabled]="curPage == 0" (click)="pagination('prev')" class="pagination-btns mr-1">
                      <span class="chevronIconWhite prev"></span>
                    </button>
                    <button class="pagination-btns" [disabled]="curPage == totalPages - 1 || totalResults == 0"
                      (click)="pagination('next')">
                      <span class="chevronIconWhite next"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <hr class="hidden-xs" />
            <div class="row row-20" [ngClass]="
                facetsList.length > 2 ? 'page-dropdown-ipad' : 'page-dropdown'
              ">
              <div class="col-md-8 col-sm-7 hidden-xs applied-filters">
                {{ "ordersList.filtersApplied" | cxTranslate }}
                <span *ngIf="!facetsList.length">{{
                  "ordersList.none" | cxTranslate
                  }}</span>
                <span id="applied-filters" class="ml-2">
                  <span class="filterText focus mr-1 mb-2" tabindex="0" *ngFor="let filter of facetsList">
                    <div>
                      {{ filter.value
                      }}<img src="../../../assets/icons/white close.png" alt="" class="remove-facet"
                        (click)="removeFacet(filter)" />
                      <span class="filterRemove"></span>
                    </div>
                  </span><a class="clear-filters ml-1" (click)="clearFilters()" *ngIf="facetsList.length">{{
                    "ordersList.clearAllFilters" | cxTranslate }}</a>
                </span>
              </div>
              <div class="col-md-4 col-sm-5 col-xs-12 sortBy-select px-12">
                <ng-select name="pageSize" [items]="pageList" [searchable]="false" [clearable]="false" bindLabel="name"
                  bindValue="code" class="set-pagination" [(ngModel)]="pageSizeperPage"
                  (ngModelChange)="changePageSize()"></ng-select>
              </div>
            </div>
          </div>
        </section>
      </section>
    </section>

    <section class="payment-section filter-section">
      <section class="dispaly-refine">
        <ng-template #facetsRwd class="select-refine">
          <section class="modal-class">
            <section class="modal-heading">
              <h2>
                <span class="close-icon" aria-hidden="true" (click)="hide()">&times;</span>
              </h2>
              <h2 class="select-heading">
                {{ "plp.selectRefinement" | cxTranslate }}
              </h2>
            </section>
            <section>
              <fieldset id="filter-by">
                <legend class="filterByTitle-main order-history-search">
                  Search by
                </legend>
                <div class="filterByGroup input-search">
                  <div class="filterByTitle">
                    <label for="invoice">{{
                      "ordersList.timeFrame" | cxTranslate
                      }}</label>
                  </div>
                  <ng-select name="timeFrame" [items]="timeFrameList" [searchable]="false" [clearable]="false"
                    bindLabel="name" bindValue="code" class="dropdown-credit" (ngModelChange)="clearDateRange()"
                    [(ngModel)]="timeFrame">
                  </ng-select>
                  <hr />
                </div>
                <div class="filterByGroup">
                  <div class="filterByTitle">
                    <label for="invoice">{{
                      "ordersList.dateRange" | cxTranslate
                      }}</label>
                  </div>
                  <div class="time-frame">
                    <div class="pr-4 template-date">
                      <div class="control-group mb-2">
                        <label for="fromDate" aria-labelledby="dataRange fromDate">{{ "ordersList.from" | cxTranslate
                          }}</label>
                      </div>
                      <div class="headSearch-holder">
                        <label class="searchbox orders-filter time-frame">
                          <img src="../../../assets/icons/calendar.svg" alt="" />
                          <input type="text" placeholder="{{
                              'ordersList.dateFormat' | cxTranslate
                            }}" class="input-filter delivery-pickup-calendar" [(ngModel)]="fromDate"
                            (ngModelChange)="validateDate()" bsDatepicker [maxDate]="maxSelectionDate"
                            [daysDisabled]="[0, 6]" [bsConfig]="{ containerClass: 'order-list-date' }" />
                        </label>
                      </div>
                    </div>
                    <div class="template-to-date">
                      <div class="control-group mb-2">
                        <label for="toDate" aria-labelledby="dataRange toDate">{{ "ordersList.to" | cxTranslate
                          }}</label>
                      </div>
                      <div class="headSearch-holder">
                        <label class="searchbox orders-filter time-frame">
                          <img src="../../../assets/icons/calendar.svg" alt="" />
                          <input type="text" placeholder="{{
                              'ordersList.dateFormat' | cxTranslate
                            }}" class="input-filter" [(ngModel)]="toDate" (ngModelChange)="validateDate()" bsDatepicker
                            [maxDate]="maxSelectionDate" [daysDisabled]="[0, 6]"
                            [bsConfig]="{ containerClass: 'order-list-date' }" />
                        </label>
                      </div>
                    </div>
                  </div>
                  <span class="dateError" *ngIf="dateError">{{
                    "ordersList.invalidDateErrMsg" | cxTranslate
                    }}</span>
                  <span class="dateError" *ngIf="singleDateError">{{
                    "ordersList.selectBothDate" | cxTranslate
                    }}</span>
                  <hr />
                </div>
                <div class="filterByGroup">
                  <div class="filterByTitle mb-2 mt-2">
                    <label for="invoice">{{
                      "ordersList.invoice" | cxTranslate
                      }}</label>
                  </div>
                  <div class="headSearch-holder input-search">
                    <label class="searchbox orders-filter time-frame">
                      <input placeholder="{{'ordersList.searchInput' | cxTranslate}}" type="text" autocomplete="off"
                        aria-describedby="initialDescription" aria-controls="results" aria-autocomplete="both"
                        class="input-filter" [(ngModel)]="invoice" />
                      <button role="presentation" class="search-icon">
                        <cx-icon [type]="iconTypes.SEARCH"></cx-icon>
                      </button>
                    </label>
                  </div>

                  <div class="filterByTitle mb-2 mt-2">
                    <label for="poNumber">{{
                      "ordersList.po" | cxTranslate
                      }}</label>
                  </div>
                  <div class="headSearch-holder input-search">
                    <label class="searchbox orders-filter time-frame">
                      <input placeholder="{{'ordersList.searchInput' | cxTranslate}}" type="text" autocomplete="off"
                        aria-describedby="initialDescription" aria-controls="results" aria-autocomplete="both"
                        class="input-filter" [(ngModel)]="poNumber" />
                      <button role="presentation" class="search-icon">
                        <cx-icon [type]="iconTypes.SEARCH"></cx-icon>
                      </button>
                    </label>
                  </div>

                  <div class="filterByTitle mb-2 mt-2">
                    <label for="orderNumber">{{
                      "ordersList.order" | cxTranslate
                      }}</label>
                  </div>
                  <div class="headSearch-holder input-search">
                    <label class="searchbox orders-filter time-frame">
                      <input placeholder="{{'ordersList.searchInput' | cxTranslate}}" type="text" autocomplete="off"
                        aria-describedby="initialDescription" aria-controls="results" aria-autocomplete="both"
                        class="input-filter" [(ngModel)]="order" />
                      <button role="presentation" class="search-icon">
                        <cx-icon [type]="iconTypes.SEARCH"></cx-icon>
                      </button>
                    </label>
                  </div>
                </div>
                <div class="account-order-filter-btn input-search">
                  <button type="button" class="btn btn-default blue-button" (click)="applyFilter('mobile')">
                    {{ "ordersList.applyFilters" | cxTranslate }}
                  </button>
                  <button type="button" id="clearOrderFiltersBtn" class="btn btn-default white-button"
                    (click)="clearFilters()">
                    {{ "ordersList.clearFilters" | cxTranslate }}
                  </button>
                </div>
              </fieldset>
            </section>
            <footer class="p-2 input-search">
              <button class="btn btn-default blue-button" (click)="applyFilter('mobile')">
                {{ "ordersList.done" | cxTranslate }}
              </button>
            </footer>
          </section>
        </ng-template>
        <div class="account-order-filter-btn mb-1">
          <button class="btn btn-default white-button" data-di-id="#refineBtn" (click)="refine()">
            {{ "ordersList.refine" | cxTranslate }}
          </button>
        </div>
        <div class="sortBy-select pb-4 mobile-view-select">
          <ng-select name="pageSize" [items]="pageList" [searchable]="false" [clearable]="false" bindLabel="name"
            bindValue="code" class="dropdown-credit set-pagination" [(ngModel)]="pageSizeperPage"
            (ngModelChange)="changePageSize()"></ng-select>
        </div>
      </section>
      <section class="row pb-4">
        <div class="col-md-8 col-xs-6">
          <span class="order-history-table">{{ "ordersList.past" | cxTranslate }}&nbsp;{{
            selectedTimeFrame
            }}</span>
        </div>
        <div class="col-md-4 col-xs-6 ad-files-text">
          <a aria-label="download order history as" id="orderXLS" (click)="generateSaveXls()">
            <img src="../../../assets/icons/MicrosoftTeams-image.png" alt="pdf" class="ad-pdf xls-img" />
            <span></span>
            <span class="pdf-text pl-1">{{
              "ordersList.xls" | cxTranslate
              }}</span>
          </a>
          <a aria-label="download order history as" id="orderPDF" (click)="generateSavePdf()">
            <img src="../../assets/icons/pdf.png" alt="pdf" class="ad-pdf" />
            <span class="ad-pdf"></span>
            <span class="pdf-text">{{ "ordersList.pdf" | cxTranslate }}</span>
          </a>
        </div>
      </section>
      <table aria-describedby="invoicesTable" id="ordersTable" class="order-list-table table table-striped">
        <thead>
          <tr>
            <th scope="col" (click)="sortColumn($event, 'placed', orders, 'Date')" class="date-title">
              {{ "ordersList.date" | cxTranslate }} &nbsp;
              <em class="my-icon fas fa-caret-down"></em>
            </th>
            <th scope="col" class="type-title" (click)="sortColumn($event, 'sapSystemType', orders, 'normal')">
              {{ "ordersList.type" | cxTranslate }} &nbsp;
              <em class="my-icon fas fa-caret-down"></em>
            </th>
            <th scope="col" (click)="sortColumn($event, 'guid', orders, 'Number')" class="order-title">
              {{ "ordersList.order" | cxTranslate }} &nbsp;
              <em class="my-icon fas fa-caret-down"></em>
            </th>
            <th scope="col" (click)="sortColumn($event, 'statusDisplay', orders, 'normal')" class="status-title">
              {{ "ordersList.status" | cxTranslate }} &nbsp;
              <em class="my-icon fas fa-caret-down"></em>
            </th>
            <th scope="col" class="po-title" (click)="
                sortColumn($event, 'purchaseOrderNumber', orders, 'normal')
              ">
              {{ "ordersList.po" | cxTranslate }} &nbsp;
              <em class="my-icon fas fa-caret-down"></em>
            </th>
             <th scope="col" class="invoice-title">
              {{ "ordersList.invoice" | cxTranslate }} &nbsp;
            </th>
            <!-- <th scope="col" class="tracking-title">
              {{ "ordersList.tracking" | cxTranslate }} &nbsp;
            </th> -->
            <th scope="col" class="total-title">
              {{"ordersList.orderAmount" | cxTranslate}}
            </th>
            <th scope="col" class="total-title">
              {{"ordersList.orderType" | cxTranslate}}
            </th>
          </tr>
        </thead>
        <tbody *ngIf="ordersList?.length">
          <tr *ngFor="
              let data of ordersList;
              let i = index
            ">
            <td>
              <span>{{ data.placedDate | date: "dd/MM/yyyy" }}</span>
            </td>
            <td>
              <span>{{ data.sapSystemType }}</span>
            </td>
            <td>
              <a [routerLink]="['/my-account/order', data.guid]" href="javascript: void(0);">
                {{ data.guid }}</a>
            </td>
            <td>{{ data.statusDisplay }}</td>
            <td>{{ data.purchaseOrderNumber }}</td>
            <td>
              <div *ngFor="let invoice of data.invoices">
                {{ invoice.invoiceNumber }}
              </div>
            </td>
            <!-- <td>
              <div *ngFor="let tracking of data.tracking">
                <a
                  class="pdf-text"
                  target="_blank"
                  href="{{ tracking.trackingURL }}"
                  >{{ tracking?.trackingNumber }}</a
                >
              </div>
            </td> -->
            <td>
              {{data.total.value | localCurrency}}
            </td>
            <td *ngIf="data.directOrder == true; else indirectOrderType">
              {{"ordersList.directType" | cxTranslate}}
            </td>
            <ng-template #indirectOrderType>
              <td>
                {{"ordersList.indirectType" | cxTranslate}}
              </td>
            </ng-template>
          </tr>
        </tbody>
      </table>

      <section class="orders-table-orders">
        <div class="row orders-row-table" *ngFor="let data of orders; let i = index">
          <div class="column header-column orders-table-col">
            <section class="col-header-rwd">
              <p>
                <b>{{ "ordersList.date" | cxTranslate }}</b>
              </p>
              <p>
                <b>
                  {{ "ordersList.type" | cxTranslate }}
                </b>
              </p>
              <p>
                <b>
                  {{ "ordersList.order" | cxTranslate }}
                </b>
              </p>
              <p>
                <b>
                  {{ "ordersList.status" | cxTranslate }}
                </b>
              </p>
              <p>
                <b>
                  {{ "ordersList.po" | cxTranslate }}
                </b>
              </p>

              <p>
                <b>
                  {{ "ordersList.invoice" | cxTranslate }}
                </b>
              </p>
              <p>
                <b>
                  {{ "ordersList.tracking" | cxTranslate }}
                </b>
              </p>
            </section>
          </div>
          <div class="column desc-column">
            <section>
              <p>
                {{ data.placedDate | date: "dd/MM/yyyy" }}
              </p>
              <p>{{ data.sapSystemType }}<span *ngIf="!data.sapSystemType">&nbsp;</span></p>
              <p>
                <a style="min-height: auto;" [routerLink]="['/my-account/order', data.guid]"
                  href="javascript: void(0);">
                  {{ data.guid }}</a>
              </p>
              <p>{{ data.statusDisplay }}<span *ngIf="!data.statusDisplay">&nbsp;</span></p>
              <p>
                {{ data.purchaseOrderNumber }}<span *ngIf="!data.purchaseOrderNumber">&nbsp;</span>
              </p>

              <div *ngFor="let invoice of data.invoices" class="set-margin">
                <a (click)="generateInvoicePDF(invoice.invoiceNumber)" class="pdf-text invoice-number">{{
                  invoice.invoiceNumber }}</a>
              </div>
              <div *ngFor="let tracking of data.tracking" class="set-margin">
                <a class="pdf-text invoice-number" target="_blank" href="{{ tracking.trackingURL }}">{{
                  tracking?.trackingNumber }}</a>
              </div>
            </section>
          </div>
        </div>
      </section>

      <div *ngIf="!ordersList.length" class="empty-list">
        {{ "ordersList.noOrders" | cxTranslate }}
      </div>
    </section>
    <section>
      <div id="prod-pagination">
        <p>
          {{ "ordersList.showing" | cxTranslate }} &nbsp;
          <span class="no_rec">{{ end }}</span>&nbsp; {{ "ordersList.of" | cxTranslate }} &nbsp;
          <span class="no_rec">{{ totalResults}}</span>&nbsp; {{ "ordersList.orders" | cxTranslate }}
        </p>
        <p class="pagination align-btns">
          <button [disabled]="curPage == 0" (click)="pagination('prev')" class="pagination-buttons mr-1">
            <span class="chevronIconWhite prev"></span>
          </button>

          <button class="pagination-buttons" [disabled]="curPage == totalPages - 1 || totalResults == 0"
            (click)="pagination('next')">
            <span class="chevronIconWhite next"></span>
          </button>
        </p>
        <p>
          <span *ngIf="totalPages != 0">{{ "ordersList.page" | cxTranslate }} {{ curPage+1 }}
            {{ "ordersList.of" | cxTranslate }} {{ totalPages }}
          </span>
          <span *ngIf="totalPages == 0">{{ "ordersList.page" | cxTranslate }} {{ curPage }}
            {{ "ordersList.of" | cxTranslate }} {{ totalPages }}
          </span>
        </p>
      </div>
    </section>

    <div class="line-intersection mobile-view"></div>
    <section class="mobile-view">
      <div>
        <app-need-to-go-back></app-need-to-go-back>
      </div>
      <div class="line-intersection"></div>
    </section>
    <section class="mobile-view">
      <div class="banner-img">
        <app-flu-dashboard-banner bannerComponent="OrderHistoryBannerComponent"></app-flu-dashboard-banner>
      </div>
    </section>
  </section>
</section>