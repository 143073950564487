<section [ngClass]="isSticky === true ? 'sticky' : 'header'" *ngIf="productEntries?.length>0">
    <section class="savings-total sticky-total">
        <h3>
            {{'addToCartItems.orderSubtotal'|cxTranslate}}
            <span *ngIf="!updateTotal;else noPrice">{{standardCartDetails?.subTotal?.value | localCurrency}}
            </span>{{'plp.cad'|cxTranslate}}
            <ng-template #noPrice>
                {{'addToCartItems.noprice'|cxTranslate}}
            </ng-template>
        </h3>
        <button *ngIf="!updateTotal" type="button" class="btn btn-default blue-button mt2 proceedCheckout"
            (click)="proceedToCheckout()" [disabled]="orderBlock || checkoutClicked || yfEntry"
            [ngClass]="orderBlock || yfEntry? 'disableCTA' :''">
            <em class="fa fa-spinner fa-spin" *ngIf="checkoutClicked"></em>
            {{'addToCartItems.proceedToCheckout'|cxTranslate}}
        </button>
        <button *ngIf="updateTotal" class="btn btn-default white-button mt2 updateOrder" (click)="updateCart()">
            {{'addToCartItems.updateOrder'|cxTranslate}}
        </button>
    </section>
</section>
<div *ngIf="showDisclaimer" class="global-alerts">
  <div class="alert alert-info alert-dismissable getAccAlert">
    <button (click)="closeDisclaimerAlert()" class="close closeAccAlert" aria-hidden="true" data-dismiss="alert"
            type="button" data-di-id="di-id-4f6d7b93-70563360">×
    </button>
    {{"addToCartItems.disclaimerMsg" | cxTranslate}}
  </div>
</div>
<div *ngIf="showDirectProductsDisclaimer" class="global-alerts">
  <div class="alert alert-danger alert-dismissable getAccAlert">
    <button (click)="closeDirectProductsDisclaimerAlert()" class="close closeAccAlert" aria-hidden="true" data-dismiss="alert"
            type="button" data-di-id="di-id-4f6d7b93-70563360">×
    </button>
    {{"addToCartItems.directProductsDisclaimerMsg" | cxTranslate}}
  </div>
</div>
<section class="preheader no-print">
    <section class="col-lg-12 col-md-12 col-sm-12 col-xs-12 float-right">
        <section class="col-lg-5 col-md-5 col-sm-0 col-xs-12 p0 float-left emptyheader"></section>
        <section class="col-lg-3 col-md-3 col-sm-6 col-xs-12 middle-row mt0 text-right float-left">
            <a href="/dz/fr_DZ/DZD/Open-Catalogue/c/1" target="" id="continueShoppingId" data-di-id="#continueShoppingId">
                <img src="../../assets/icons/chevron-left.svg" alt="" class="chevron-left">
                {{'addToCartItems.continueShopping'|cxTranslate}}
            </a>
        </section>
        <section class="col-lg-4 col-md-4 col-sm-6 col-xs-12 text-right float-right p0 header-links"
            *ngIf="productEntries?.length>0">
            <section class="save-document save-document-container  mb1 mt0 ">
                <ng-template #popSaveTitle>{{'addToCartItems.saveOrderTitle'|cxTranslate}}</ng-template>
                <ng-template #popSaveContent>
                    <input type="text" class="save-order" required #name="ngModel" [(ngModel)]="saveCartName"
                        (keypress)="omit_special_char($event)" maxlength="30">
                    <span *ngIf="name.errors?.required" class="common-error required">{{errorMsg}}</span>
                    <span class="common-error required">{{errorMsg}}</span>
                    <button type="reset" class="popover-close btn white-button popover-cancel-button"
                        (click)="toggle(p2)">
                        {{'addToCartItems.cancel'|cxTranslate}}</button>
                    <button class="btn blue-button popover-cancel-button" type="submit"
                        data-di-id="di-id-3064f17d-e97cc628" (click)="saveOrder()">
                        {{'addToCartItems.save'|cxTranslate}}</button>
                </ng-template>
                <a class="save-cart save-cart-img-section" [ngbPopover]=popSaveContent [popoverTitle]=popSaveTitle
                    triggers="manual" #p2="ngbPopover" [placement]="popoverPosition" (click)="toggle(p2)" container="body"
                    popoverClass="popover-links saveorder-incart">
                    <img src="../../assets/icons/saved-carts.svg"
                        alt="Save order"><span>{{'addToCartItems.saveOrder'|cxTranslate}}</span></a>
                <ng-template #popTitle><span>
                        {{'addToCartItems.clearTitle'|cxTranslate}}</span>
                </ng-template>
                <ng-template #popContent>
                    <button type="reset" class="popover-close btn
                     white-button popover-cancel-button" data-di-id="di-id-d01008be-fb6e135c" (click)="toggle(p1)">
                        {{'addToCartItems.cancel'|cxTranslate}}</button>
                    <button class="btn blue-button popover-cancel-button" type="submit"
                        data-di-id="di-id-3064f17d-e97cc628" (click)="clearChildCart()">
                        {{'addToCartItems.clearCart'|cxTranslate}}</button>
                </ng-template>
                <a class="clear-cart clear-cart-img-section" [ngbPopover]=popContent [popoverTitle]=popTitle
                    triggers="manual" #p1="ngbPopover" placement="top" (click)="toggle(p1)" container="body"
                    popoverClass="popover-links">
                    <img src="../../assets/icons/trash.svg" alt="Delete">
                    <span>{{'addToCartItems.clearCart'|cxTranslate}}</span></a>

                <a href="javascript:void(0)" target="" aria-label="download as" class="xls_cart xls-cart-img-section"
                    id="tealium-xls" data-di-id="#tealium-xls" (click)="exportXls()">
                    <img src="../../assets/icons/excel.png" alt="">
                    <span>{{'addToCartItems.xls'|cxTranslate}} </span></a>
                <a href="javascript:void(0)" onclick="window.print();" target="" id="printLinkId"
                    class="print-cart-img-section" data-di-id="#printLinkId">
                    <img src="../../assets/icons/print.svg" alt="">
                    <span>{{'addToCartItems.print'|cxTranslate}} </span></a>
            </section>
        </section>
    </section>
</section>
<div *ngIf="deleteSuccess!==''" class="global-alerts">

    <div class="alert alert-info alert-dismissable getAccAlert">
        <button (click)="closeAlert()" class="close closeAccAlert" aria-hidden="true" data-dismiss="alert" type="button"
            data-di-id="di-id-4f6d7b93-70563360">×</button>
        <span class="delete-succ" [innerHTML]="deleteSuccess"></span>{{'addToCartItems.deleteSuccessMsg'|cxTranslate}}
    </div>
</div>
<div *ngIf="addSuccess" class="global-alerts">

    <div class="alert alert-info alert-dismissable getAccAlert">
        <button (click)="closeAlert()" class="close closeAccAlert" aria-hidden="true" data-dismiss="alert" type="button"
            data-di-id="di-id-4f6d7b93-70563360">×</button>
        {{'addToCartItems.addProdSuccess'|cxTranslate}}
    </div>
</div>
<div *ngIf="orderBlock && showDangerAlert && productEntries?.length>0" class="global-alerts">

    <div class="alert alert-danger alert-dismissable getAccAlert">
        <button (click)="closeDangerAlert()" class="close closeAccAlert" aria-hidden="true" data-dismiss="alert"
            type="button" data-di-id="di-id-4f6d7b93-70563360">×</button>
        {{'addToCartItems.orderBlockMsg'|cxTranslate}}
    </div>
</div>
<section class="standart-cart" *ngIf="productEntries?.length>0; else emptyCart">
    <section class="row-20 checkout ch-prnt" id="leftCont-rightNav-cart">
        <aside class="col-md-4 col-sm-12 right-navigation float-right right-align cart-main-page"
            [ngClass]="isSticky === true ? 'header' : ''" aria-label="subTotal">
            <section class="savings-total">
                <h3>
                    {{'addToCartItems.orderSubtotal'|cxTranslate}}
                    <span *ngIf="!updateTotal;else noPrice">{{standardCartDetails?.subTotal?.value | localCurrency}}
                    </span>{{'plp.cad'|cxTranslate}}
                    <ng-template #noPrice>
                        {{'addToCartItems.noprice'|cxTranslate}}
                    </ng-template>
                </h3>
                <button *ngIf="!updateTotal" type="button"
                    class="btn btn-default blue-button mt2 proceedCheckout no-print" (click)="proceedToCheckout()"
                    [disabled]="orderBlock || checkoutClicked || yfEntry"
                    [ngClass]="orderBlock || yfEntry? 'disableCTA' :''">
                    <i class="fa fa-spinner fa-spin" *ngIf="checkoutClicked"></i>
                    {{'addToCartItems.proceedToCheckout'|cxTranslate}}
                </button>
                <button *ngIf="updateTotal" class="btn btn-default white-button mt2 updateOrder" (click)="updateCart()">
                    {{'addToCartItems.updateOrder'|cxTranslate}}
                </button>
            </section>
        </aside>
        <section class="col-md-8 col-sm-12 left-section float-left cart-left" style="min-height: 357px;">
            <section class="cart-accordion">
                <section class="panel-group">
                    <section class="panel panel-default">
                        <section class="panel-heading">
                            <section class="col-xs-12 col-sm-12 col-md-12 Fields-container delivary_ord" *ngIf="cartContainsDirectProducts">
                                <section class="delivary_opt">

                                    <section class="row radio-section w-100">
                                        <label>{{'addToCartItems.selectOption'|cxTranslate}}</label>

                                        <section class="custom-control custom-radio radio_grp1">
                                            <input id="delivery" type="radio" class="custom-control-input"
                                                value="delivery" name="deliveryPickup" [(ngModel)]="deliveryPickup">
                                            <label class="custom-control-label"
                                                for="delivery">{{'addToCartItems.delivery'|cxTranslate}}</label>
                                        </section>

                                        <section class="custom-control custom-radio radio_grp1">
                                            <input id="pickup" type="radio" class="custom-control-input" value="pickup"
                                                name="deliveryPickup" [(ngModel)]="deliveryPickup">
                                            <label class="custom-control-label"
                                                for="pickup">{{'addToCartItems.pickup'|cxTranslate}}</label>
                                        </section>


                                    </section>
                                  <p style="color: red"
                                     *ngIf="cartContainsDirectProducts && cartContainsWholesalerProducts">{{'addToCartItems.deliveryOptionsInfo'|cxTranslate}}</p>
                                </section>
                            </section>
                            <section class="deli_loc clear_fix" *ngIf="deliveryPickup=='delivery'">
                                <section class="cart_shipto col-sm-6 col-md-6 col-lg-6 cart_shipto pl0">
                                    <section class="firstLevel-acc cartShipToHeader ">


                                        <h6>{{'addToCartItems.shipLocation'|cxTranslate}}</h6>
                                        <section class="col-sm-7 col-md-7 col-lg-7   locleft">
                                            <section class="ship-address">
                                                <section id="location-select">
                                                    <section class="location-icon">
                                                        <span class="glyph-icon">
                                                            <i class="fas fa-map-marker-alt icon mr-3"></i>
                                                        </span>
                                                    </section>
                                                    <section class="location-Details">
                                                        <span class="selected-loc">{{selectedData?.companyName}}
                                                            {{"("+selectedData?.unitID+")"}} </span>
                                                        <span class="addr-label">
                                                            {{selectedData?.line1 ? selectedData?.line1+', ' : ''}}
                                                            {{selectedData?.line2 ? selectedData?.line2+', ' : ''}}
                                                            {{selectedData?.town ? selectedData?.town+', ' : ''}}
                                                            {{selectedData?.region?.isocodeShort ?
                                                            selectedData?.region?.isocodeShort+' ' : ''}}
                                                            {{selectedData?.postalCode}}</span>
                                                        <div class="change-loc" role="button" *ngIf="changeLocation"
                                                            (click)="open(content)">{{'account.changeLocation'|
                                                            cxTranslate}}</div>
                                                    </section>
                                                    <section class="custome-modal">
                                                        <ng-template #content let-modal>
                                                            <section class="modal-header cart-Modal-Header">
                                                                <button type="button" class="close" aria-label="Close"
                                                                    (click)="modal.dismiss('Cross click')">
                                                                    <img alt="" src="../../assets/icons/close.png">
                                                                </button>
                                                                <h4 class="modal-title" id="modal-basic-title">
                                                                    {{'account.selectLocation'| cxTranslate}}</h4>
                                                            </section>
                                                            <section class="modal-body">

                                                                <section class="input-group loc-SearchBar">
                                                                    <span class="input-group-btn">
                                                                        <button class="btn" type="button">
                                                                            <span class="loc-Search-Icon">
                                                                            </span>
                                                                        </button>
                                                                        <input type="text" #clientName
                                                                            (keyup)="SearchClientName(clientName.value)"
                                                                            class="form-control searchboxnew"
                                                                            placeholder="{{'account.filterByLocation'| cxTranslate}}	" />
                                                                    </span>
                                                                </section>
                                                                <hr />
                                                                <section>
                                                                    <section
                                                                        class="custom-control custom-radio radio_grp label-location w-100 m-0">
                                                                        <table aria-describedby="shiptolocation"
                                                                            *ngFor="let data of searchedClientList;  let i = index"
                                                                            class="w-100">
                                                                            <tr class="sample-header">
                                                                                <th scope="col"></th>
                                                                                <th scope="col"></th>
                                                                            </tr>
                                                                            <tr class="line-location"
                                                                                [ngClass]="{'label-location': (i !== searchedClientList.length-1)}">

                                                                                <input type="radio"
                                                                                    (change)='onOptionsSelected(data)'
                                                                                    [id]="data?.id"
                                                                                    [checked]="selectedData?.id == data?.id"
                                                                                    name="customRadio"
                                                                                    class="custom-control-input" />
                                                                                <label
                                                                                    class="custom-control-label secloct w-100"
                                                                                    [for]="data?.id">
                                                                                    {{data?.companyName}}
                                                                                    {{"("+data?.unitID+")"}}
                                                                                </label>
                                                                                <p class="labelpp"> {{data?.line1 ?
                                                                                    data?.line1+', ' : ''}}
                                                                                    {{data?.line2 ? data?.line2+', ' :
                                                                                    ''}}
                                                                                    {{data?.town ? data?.town+', ' :
                                                                                    ''}}
                                                                                    {{data?.region?.isocodeShort ?
                                                                                    data?.region?.isocodeShort+' ' :
                                                                                    ''}}
                                                                                    {{data?.postalCode}}
                                                                                </p>

                                                                            </tr>
                                                                        </table>
                                                                    </section>

                                                                </section>
                                                            </section>
                                                            <section class="modal-footer">
                                                                <button type="button" class="btn location-done"
                                                                    (click)='onSubmit()'
                                                                    (click)="modal.close('Save click')">
                                                                    {{'account.done'| cxTranslate}}
                                                                </button>
                                                            </section>
                                                        </ng-template>


                                                    </section>
                                                </section>

                                            </section>

                                        </section>
                                    </section>
                                </section>
                                <section class=" col-sm-6 col-md-6 col-lg-6 ord_date float-right" *ngIf="cartContainsDirectProducts">
                                    <section class="delivery-content multipleLocDelivery">
                                        <section class="delivary-details cart-delivery-date">
                                            <h6>{{'addToCartItems.deliveryDate'|cxTranslate}}</h6>
                                            <section class="calender">
                                                <section class="form-horizontal mb0">
                                                    <section class="control-group">
                                                        <section
                                                            class="controls input-append date cart-delivery-date-picker"
                                                            data-date="" data-date-format="mm/dd/yyyy"
                                                            data-link-field="dtp_input2" data-link-format="mm/dd/yyyy">
                                                            <span class="add-on"><i class="icon-th"></i></span>
                                                            <input
                                                                *ngIf="sessionStorage.getItem('accountType') === 'DIRECT'"
                                                                type="text" placeholder="DD/MM/YYYY"
                                                                class="delivery-pickup-calendar" bsDatepicker
                                                                [(ngModel)]="deliveryDate" [daysDisabled]="[5,6]"
                                                                [minDate]="minDeliveryDate" [maxDate]="maxDeliveryDate"
                                                                (bsValueChange)="onDeliveryDateChange($event)"
                                                                [bsConfig]="{ containerClass: 'theme-green deliveryPickup' }">
                                                            <input
                                                                *ngIf="sessionStorage.getItem('accountType') !== 'DIRECT'"
                                                                type="text" placeholder="DD/MM/YYYY"
                                                                class="delivery-pickup-calendar" bsDatepicker
                                                                [(ngModel)]="deliveryDate" [minDate]="minDeliveryDate"
                                                                [maxDate]="maxDeliveryDate"
                                                                (bsValueChange)="onDeliveryDateChange($event)"
                                                                [bsConfig]="{ containerClass: 'theme-green deliveryPickup' }">
                                                        </section>

                                                        <p class="mx0 mb1" style="margin-bottom: 0px">
                                                            {{'addToCartItems.deliveryMsg'|cxTranslate}}
                                                        </p>
                                                      <p style="color: red"
                                                         *ngIf="cartContainsDirectProducts && cartContainsWholesalerProducts">{{'addToCartItems.deliveryOptionsInfo'|cxTranslate}}</p>

                                                    </section>
                                                </section>
                                            </section>
                                        </section>
                                    </section>
                                </section>
                            </section>
                            <section class="ship_addr clear_fix" *ngIf="deliveryPickup=='pickup'">
                                <section class="col-sm-4 col-md-4 col-lg-4 cart_shipto pl0 float-left">
                                    <h6>{{'addToCartItems.pickLocation'|cxTranslate}}</h6>
                                    <p>{{'addToCartItems.sanofiName'|cxTranslate}}<br>{{'addToCartItems.locationLine1'|cxTranslate}}<br>{{'addToCartItems.locationLine2'|cxTranslate}}
                                    </p>
                                </section>
                                <section class=" col-sm-6 col-md-6 col-lg-6 px0 float-left">
                                    <section class="delivery-content">
                                        <section class="delivary-details cart-pickup-date">
                                            <h6 class="requested-date"> {{'addToCartItems.pickupDate'|cxTranslate}}</h6>
                                            <section class="calender">
                                                <section class="form-horizontal mb0">
                                                    <section class="control-group float-left">
                                                        <section
                                                            class="controls input-append date cart-delivery-date-picker"
                                                            data-date="" data-date-format="mm/dd/yyyy"
                                                            data-link-field="dtp_input2" data-link-format="mm/dd/yyyy">
                                                            <span class="add-on"><i class="icon-th"></i>
                                                            </span>
                                                             <input
                                                            *ngIf="sessionStorage.getItem('accountType') === 'DIRECT'"
                                                                type="text" placeholder="DD/MM/YYYY"
                                                                class="delivery-pickup-calendar" bsDatepicker
                                                                [(ngModel)]="pickupDate" [minDate]="minPickupDate"
                                                                [daysDisabled]="[5,6]" [maxDate]="maxPickupDate"
                                                                (bsValueChange)="onPickupDateChange($event)"
                                                                [bsConfig]="{ containerClass: 'theme-green deliveryPickup' }">
                                                            <input
                                                                *ngIf="sessionStorage.getItem('accountType') !== 'DIRECT'"
                                                                type="text" placeholder="DD/MM/YYYY"
                                                                class="delivery-pickup-calendar" bsDatepicker
                                                                [(ngModel)]="pickupDate" [minDate]="minPickupDate"
                                                                [maxDate]="maxPickupDate"
                                                                (bsValueChange)="onPickupDateChange($event)"
                                                                [bsConfig]="{ containerClass: 'theme-green deliveryPickup' }">
                                                        </section>
                                                    </section>
                                                    <ng-select [items]="timeFormat" bindLabel="name"
                                                        bindValue="selectedTime" [multiple]="false" name="selectedTime"
                                                        placeholder="Set Time" [(ngModel)]="selectedTime"
                                                        [searchable]="false">
                                                    </ng-select>

                                                </section>
                                            </section>
                                        </section>
                                        <p class="pickupTextMsg">
                                            {{'addToCartItems.pickupMsg'|cxTranslate}}
                                            <br>{{'addToCartItems.customerService'|cxTranslate}}
                                            {{'addToCartItems.customerServiceNo'|cxTranslate}}
                                        </p>
                                    </section>
                                </section>

                            </section>
                            <section id="pdp-list1" class="panel-collapse clear_fix">
                                <section class="panel-body no-location-panel">
                                    <section *ngFor="let productentry of productEntries" class="pdp-list-data">
                                        <section class="row row-20 pdp-list-product-data">
                                            <section class="col-xs-12 col-sm-7 col-md-7 col-lg-7 pr0 cart_print">
                                                <a (click)="productDetails(productentry)">
                                                    <img class="image-product" *ngIf="productentry?.PRIMARY!==undefined"
                                                        src="{{productentry?.PRIMARY?.url}}" alt="">

                                                    <img class="image-product" *ngIf="productentry?.PRIMARY===undefined"
                                                        [src]="fallbackImage" alt="">
                                                    <h2 [innerHTML]="productentry.product.name"></h2>
                                                </a>
                                            </section>
                                        </section>
                                        <section class="row">
                                            <!--Entity for space-->
                                            <section class="col-lg-2 col-md-2 col-sm-2 hidden-xs
                                             delete-popover-container min-col-width pr0 width13 no-print">
                                                <ng-template #popTitleDelete>
                                                    {{'addToCartItems.deleteTitle'|cxTranslate}}</ng-template>
                                                <ng-template #popContentDelete>
                                                    <button type="reset"
                                                        class="popover-close btn white-button popover-cancel-button"
                                                        data-di-id="di-id-d01008be-fb6e135c" (click)="toggle(p1)">
                                                        {{'addToCartItems.cancel'|cxTranslate}}</button>
                                                    <button class="btn blue-button popover-cancel-button" type="submit"
                                                        data-di-id="di-id-3064f17d-e97cc628"
                                                        (click)="deleteCart(productentry?.entryNumber,productentry?.product?.name)">
                                                        {{'addToCartItems.delete'|cxTranslate}}</button>
                                                </ng-template>

                                                <a [ngbPopover]="popContentDelete" [popoverTitle]="popTitleDelete"
                                                    triggers="manual" #p1="ngbPopover" placement="top"
                                                    class="trash cart-edit-delete" (click)="toggle(p1)">
                                                    <img src="../../assets/icons/trash.svg" alt="Delete">
                                                    {{'addToCartItems.delete'|cxTranslate}}
                                                </a>
                                            </section>
                                            <section class=" data-pad" [ngClass]="(productentry?.basePrice?.value == undefined && productentry?.basePrice?.value == 0.0)?'col-sm-4 col-md-4 col-lg-4':'col-sm-5 col-md-5 col-lg-5'">
                                                <section class="checkout-data">
                                                    <p [innerHTML]="productentry.product.description"></p>
                                                    <p class="bold">
                                                        {{'plp.productNumber'|cxTranslate}}: &nbsp;<span
                                                            class="num-bold productCode">{{productentry.product.code}}</span>
                                                    </p>
                                                    <p *ngIf="!!productentry?.wholesalerDisplayName && (sessionStorage.getItem('accountType') === 'INDIRECT' || sessionStorage.getItem('accountType') === 'MIXED')" class="bold">{{'plp.soldBy'|cxTranslate}}&nbsp;
                                                      {{productentry?.wholesalerDisplayName}}</p>
                                                </section>
                                            </section>
                                            <section class="col-sm-3  price-list" [ngClass]="(productentry?.basePrice?.value == 0.0) ? 'col-lg-2 col-md-1':'col-lg-3 col-md-2'">
                                                <section class="product-price">
                                                    <h6>
                                                        {{'plp.yourPrice'|cxTranslate}}
                                                    </h6>
                                                    <span>{{productentry?.basePrice?.value | localCurrency}}
                                                        {{'plp.cad'|cxTranslate}}
                                                    </span>

                                                </section>
                                            </section>

                                            <section class="col-xs-6 col-sm-2 col-lg-2 px0 col-md-3 unit-list"
                                                *ngIf="productentry?.basePrice?.value != undefined && productentry?.basePrice?.value != 0.0">
                                                <section class="unitCount-comp text-center">
                                                    <section class="addUnits-head bold">
                                                        {{'plp.units'|cxTranslate}}
                                                    </section>

                                                    <section class="unit-area">
                                                        <span class="disabled " aria-hidden="true" class="no-print"
                                                            id="{{'minusIcon'+productentry?.entryNumber}}"
                                                            (click)="performQuantityComputation(2,productentry?.product?.code,productentry?.product?.quantityPerUnit,productentry.entryNumber)">
                                                            <img src="../../assets/icons/less.svg"
                                                                id="{{'unitMinus'+productentry?.entryNumber}}"
                                                                [ngClass]="{'disabled-img':productentry?.quantity===1}"
                                                                alt="">
                                                            <img src="../../assets/icons/less-disable.svg"
                                                                id="{{'unitMinusDisable'+productentry?.entryNumber}}"
                                                                alt=""
                                                                [ngClass]="{'disabled-img':productentry?.quantity>1}">
                                                        </span>
                                                        <input type="number" class="prod-quantity text-center"
                                                            value={{productentry?.quantity}}
                                                            (keyup)="performQuantityComputation(null,productentry?.product?.code,productentry?.product?.quantityPerUnit,productentry.entryNumber)"
                                                            id="{{'prodQuantity'+productentry?.entryNumber}}" min="1"
                                                            max="9999999"
                                                            onkeypress="return /[0-9a-zA-Z]/i.test(event.key)" />
                                                        <span class="" aria-hidden="true" class="no-print"
                                                            id="{{'plusIcon'+productentry?.entryNumber}}"
                                                            (click)="performQuantityComputation(1,productentry?.product?.code,productentry?.product?.quantityPerUnit,productentry.entryNumber)">
                                                            <img src="../../assets/icons/more.svg"
                                                                id="{{'unitPlus'+productentry?.entryNumber}}" alt="">
                                                            <img src="../../assets/icons/more-disable.svg"
                                                                id="{{'unitPlusDisable'+productentry?.entryNumber}}"
                                                                class="disabled-img" alt="">
                                                        </span>
                                                    </section>
                                                    <section class="doses-section"
                                                        id="{{'noOfDoses'+productentry?.entryNumber}}">
                                                        {{productentry?.product?.quantityPerUnit*productentry?.quantity}}
                                                        {{'plp.doses'|cxTranslate}}
                                                    </section>
                                                </section>
                                            </section>
                                            <section class="col-xs-6 col-sm-2 col-lg-3 px0 col-md-3 unit-list"
                                            [ngClass]="(productentry?.basePrice?.value == 0.0) ? 'no-price-width':''"
                                              *ngIf="productentry?.basePrice?.value == undefined || productentry?.basePrice?.value == 0.0">
                                              <section class="no-price"><p>{{'plp.noPrice' | cxTranslate}}</p></section>
                                          </section>
                                            <section class="col-lg-2 col-md-2 col-sm-2 hidden-xs
                                            delete-popover-container-rwd min-col-width pr0 width13 no-print">
                                                <ng-template #popTitleDelete>
                                                    {{'addToCartItems.deleteTitle'|cxTranslate}}</ng-template>
                                                <ng-template #popContentDelete>
                                                    <button type="reset"
                                                        class="popover-close btn white-button popover-cancel-button"
                                                        data-di-id="di-id-d01008be-fb6e135c" (click)="toggle(p1)">
                                                        {{'addToCartItems.cancel'|cxTranslate}}</button>
                                                    <button class="btn blue-button popover-cancel-button" type="submit"
                                                        data-di-id="di-id-3064f17d-e97cc628"
                                                        (click)="deleteCart(productentry?.entryNumber,productentry?.product?.name)">
                                                        {{'addToCartItems.delete'|cxTranslate}}</button>
                                                </ng-template>

                                                <a [ngbPopover]="popContentDelete" [popoverTitle]="popTitleDelete"
                                                    triggers="manual" #p10="ngbPopover" [placement]="popoverPosition"
                                                    class="trash cart-edit-delete" (click)="openPopover(p10)">
                                                    <img src="../../assets/icons/trash.svg" alt="Delete">
                                                    {{'addToCartItems.delete'|cxTranslate}}
                                                </a>
                                            </section>
                                        </section>
                                    </section>
                                </section>
                            </section>

                        </section>
                    </section>
                </section>
            </section>
        </section>
        <aside class="col-md-4 col-sm-12 right-navigation float-right right-align add-products no-print"
               aria-label="quickOrderSection"
               *ngIf="sessionStorage.getItem('accountType') === 'DIRECT'">
            <section class="ad-product-details cartProductSaveDetails">
                <h3>{{'addToCartItems.addProducts'|cxTranslate}}</h3>
                <h6>{{'addToCartItems.quickOrder'|cxTranslate}}</h6>
                <p>{{'addToCartItems.searchItems'|cxTranslate}}</p>
                <app-quick-order></app-quick-order>
            </section>
        </aside>
    </section>

</section>
<ng-template #emptyCart>
    <app-empty-cart compName="EmptyCartParagraphComponent"></app-empty-cart>
</ng-template>
