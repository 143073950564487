<section class="row invoicesView">
    <aside class="col-md-3 col-sm-3 col-lg-2 col-xs-12 invoice-filter pr-0">
      <fieldset id="filter-by">

        <legend class="filterByTitle-main order-history-search">
          {{ "invoiceCredit.searchBy" | cxTranslate }}
        </legend>

        <div class="grey-line"></div>

        <!-- Timeframe -->
        <div class="filterByGroup mb-3">
          <div class="filterByTitle">
            <label for="invoice">{{
              "invoiceCredit.timeFrame" | cxTranslate
            }}</label>
          </div>
          <ng-select
            name="timeFrame"
            [items]="timeFrameList"
            [searchable]="false"
            [clearable]="false"
            bindLabel="name"
            bindValue="code"
            class="dropdown-credit"
            (ngModelChange)="clearDateRange()"
            [(ngModel)]="timeFrame"
          >
          </ng-select>
        </div>
        <br><br><br>
        <div class="grey-line"></div>
        <!--Date Range-->
        <div class="filterByGroup">
          <div class="filterByTitle mb-2">
            {{ "invoiceCredit.dateRange" | cxTranslate }}
          </div>
          <div class="delivary-details">
            <div>
              <fieldset class="form-horizontal mb0">
                <legend class="control-group mb-2">
                  <label for="fromDate" aria-labelledby="dataRange fromDate">{{
                    "invoiceCredit.from" | cxTranslate
                  }}</label>
                </legend>
                <div class="headSearch-holder">
                  <label class="searchbox orders-filter time-frame">
                    <img src="../../../assets/icons/calendar.svg" alt="" />
                    <input
                      type="text"
                      placeholder="{{ 'invoiceCredit.dateFormat' | cxTranslate }}"
                      class="input-filter"
                      [(ngModel)]="fromDate"
                      (ngModelChange)="validateDate()"
                      bsDatepicker
                      [maxDate]="maxSelectionDate"
                      [daysDisabled]="[0, 6]"
                      [bsConfig]="{ containerClass: 'order-list-date' }"
                    />
                  </label>
                </div>
              </fieldset>
            </div>
          </div>
          <div class="delivary-details">
            <div>
              <fieldset class="form-horizontal mb0">
                <legend class="control-group mb-2">
                  <label for="toDate" aria-labelledby="dataRange toDate">{{
                    "invoiceCredit.to" | cxTranslate
                  }}</label>
                </legend>
                <div class="headSearch-holder">
                  <label class="searchbox orders-filter time-frame">
                    <img src="../../../assets/icons/calendar.svg" alt="" />
                    <input
                      type="text"
                      placeholder="{{ 'invoiceCredit.dateFormat' | cxTranslate }}"
                      class="input-filter"
                      [(ngModel)]="toDate"
                      (ngModelChange)="validateDate()"
                      bsDatepicker
                      [maxDate]="maxSelectionDate"
                      [daysDisabled]="[0, 6]"
                      [bsConfig]="{ containerClass: 'order-list-date' }"
                    />
                  </label>
                </div>
              </fieldset>
            </div>
          </div>
          <span class="dateError" *ngIf="dateError">{{
            "invoiceCredit.invalidDateErrMsg" | cxTranslate
          }}</span>
          <span class="dateError" *ngIf="singleDateError">{{
            "invoiceCredit.selectBothDate" | cxTranslate
          }}</span>
        </div>
        <!--End-->

        <div class="grey-line"></div>

        <!-- Invoice number -->
        <div class="filterByGroup">
          <div class="filterByTitle mb-2">
            <label for="invoice">{{ "invoiceCredit.creditInvoice" | cxTranslate }}</label>
          </div>
          <div class="headSearch-holder">
            <label class="searchbox orders-filter time-frame">
              <input
                placeholder="{{'invoiceCredit.searchInput' | cxTranslate}}"
                type="text"
                autocomplete="off"
                aria-describedby="initialDescription"
                aria-controls="results"
                aria-autocomplete="both"
                class="input-filter"
                [(ngModel)]="creditOrInvoiceNumber"
              />
              <button role="presentation" class="search-icon">
                <cx-icon [type]="iconTypes.SEARCH"></cx-icon>
              </button>
            </label>
          </div>
        </div>

        <!-- Apply / Cancel filters -->
        <div class="account-order-filter-btn">
          <button
            type="submit"
            class="btn btn-default blue-button"
            data-di-id="di-id-362f2c77-5f23e741"
            (click)="applyFilter()"
          >
            {{ "invoiceCredit.applyFilters" | cxTranslate }}
          </button>
          <button
            type="button"
            id="clearOrderFiltersBtn"
            class="btn btn-default white-button"
            data-di-id="#clearOrderFiltersBtn"
            (click)="clearFilters()"
          >
            {{ "invoiceCredit.clearFilters" | cxTranslate }}
          </button>
        </div>
      </fieldset>
      <div class="line-intersection"></div>
      <section class="hide-sm">
        <div class="banner-img"></div>
      </section>
    </aside>

    <section class="col-md-9 col-sm-9 col-lg-10 invoice-body">
        <!-- INVOICES -->
        <section *ngIf="openCreditInvoice?.length === 0 || paymentList?.length === 0">
            <h3 class="no-order-tittle">
                {{ "invoiceCredit.invoiceTitle" | cxTranslate }}
            </h3>
            <section class="no-credit-invoice">
                {{ "invoiceCredit.no_open_invoices" | cxTranslate }}
            </section>
        </section>
        <section *ngIf="paymentList.length > 0" class="payment-section">
            <section class="row">
                <section class="col-sm-12 col-md-12 col-md-12 col-xs-12 op_ovice">
                    <h3 class="oi-table-title">
                        {{ "invoiceCredit.invoiceTitle" | cxTranslate }}
                    </h3>
                </section>
            </section>
            <table aria-describedby="invoicesTable" id="applyCreditsTable" class="payments-table table table-striped">
                <thead>
                    <tr>
                        <th scope="col" class="col-width" (click)="sortColumn($event, 'refDocNo', paymentList, 'normal')">
                            {{ "invoiceCredit.invoice" | cxTranslate }}
                            <em class="my-icon fas fa-caret-down"></em>
                        </th>
                        <th scope="col" class="col-width"
                            (click)="sortColumn($event, 'docDate', paymentList, 'Date')">
                            {{ "invoiceCredit.invoice_date" | cxTranslate }}
                            <em class="my-icon fas fa-caret-down"></em>
                        </th>
                        <th scope="col" class="col-width" (click)="sortColumn($event, 'dueDate', paymentList, 'Date')">
                            {{ "invoiceCredit.due_date" | cxTranslate }}
                            <em class="my-icon fas fa-caret-up"></em>
                        </th>
                        <th scope="col" class="col-width" (click)="sortColumn($event, 'amount', paymentList, 'Number')">
                            {{ "invoiceCredit.invoice_balance" | cxTranslate }}
                            <em class="my-icon fas fa-caret-down"></em>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of paymentList; let i = index">
                        <td class="pay">
                            <span>
                              {{ data.refDocNo }}
                          </span>
                        </td>
                        <td class="pay">{{ data.docDate }}</td>
                        <td class="pay">
                            <span [ngClass]="!!data.dueDate && dateComparison(data.dueDate) ? 'red':''">{{ data.dueDate
                                }}
                                <ng-template #popSaveContent>
                                    <span class="popover-content">{{
                                        "invoiceCredit.past_due_date" | cxTranslate
                                        }}</span>
                                </ng-template>
                                <span [ngbPopover]="popSaveContent" triggers="manual" #p1="ngbPopover" placement="top"
                                    (click)="toggle(p1)" container="body" popoverClass="popover-links-invoice"
                                    *ngIf="!!data.dueDate && dateComparison(data.dueDate)">
                                    <img src="assets/icons/warning_red.png" alt="warningIcon"
                                        class="warningIconEvent" />
                                </span>
                            </span>
                        </td>
                        <td class="pay">{{ data.amount | localCurrency }}</td>
                    </tr>
                </tbody>
            </table>

            <section class="invoices-table-2column">
                <div class="row invoices-row-table" *ngFor="let data of paymentList; let i = index">
                    <div class="column invoices-col-headerrwd">
                        <section class="">
                            <p scope="col" (click)="sortColumn($event, 'docNo', paymentList, 'normal')">
                                {{ "invoiceCredit.invoice#" | cxTranslate }}
                                <em class="my-icon fas fa-caret-down"></em>
                            </p>
                            <p scope="col" (click)="sortColumn($event, 'docDate', paymentList, 'Date')">
                                {{ "invoiceCredit.invoice_date" | cxTranslate }}
                                <em class="my-icon fas fa-caret-down"></em>
                            </p>
                            <p scope="col" (click)="sortColumn($event, 'dueDate', paymentList, 'Date')">
                                {{ "invoiceCredit.due_date" | cxTranslate }}
                                <em class="my-icon fas fa-caret-up"></em>
                            </p>
                            <p scope="col" class="" (click)="sortColumn($event, 'amount', paymentList, 'Number')">
                                {{ "invoiceCredit.invoice_balance" | cxTranslate }}
                                <em class="my-icon fas fa-caret-down"></em>
                            </p>
                        </section>
                    </div>
                    <div class="column desc-column">
                        <section>
                            <p class="pay">
                                <span>
                                  {{ data.docNo }}
                              </span>
                            </p>
                            <p class="pay">{{ data.docDate }}</p>
                            <p class="pay">
                                <span class="red">{{ data.dueDate }}
                                    <ng-template #popSaveContent>
                                        <span class="popover-content">{{
                                            "invoiceCredit.past_due_date" | cxTranslate
                                            }}</span>
                                    </ng-template>
                                    <span [ngbPopover]="popSaveContent" triggers="manual" #p1="ngbPopover"
                                        placement="top" (click)="toggle(p1)" container="body"
                                        popoverClass="popover-links-invoice"
                                        *ngIf="!!data.dueDate && dateComparison(data.dueDate)">
                                        <img src="assets/icons/warning_red.png" alt="warningIcon"
                                            class="warningIconEvent" />
                                    </span>
                                </span>
                            </p>
                            <p class="pay">{{ data.amount | localCurrency }}</p>
                        </section>
                    </div>
                </div>
            </section>



            <button [routerLink]="['/', 'openInvoicePayment']" [ngClass]="invoiceItems.length != 0 ? '' : 'display'"
                type="button" class="btn btn-primary float-right btnpaymentinvoicecredit"
                (click)="initiateInvoicePaymentFlow()">
                {{ "invoiceCredit.continue_button_txt" | cxTranslate }}
            </button>
        </section>

        <!-- CREDITS -->
        <section *ngIf="openCreditInvoice?.length === 0 || creditList?.length === 0">
          <h3 class="no-order-tittle">
              {{ "invoiceCredit.credits_title" | cxTranslate }}
          </h3>
          <section class="no-credit-invoice">
              {{ "invoiceCredit.no_open_credits" | cxTranslate }}
          </section>
      </section>
      <section *ngIf="creditList.length > 0" class="credits-section">
          <section class="row">
              <section class="col-sm-12 col-md-12 col-md-12 col-xs-12 op_ovice">
                  <h3 class="oi-table-title">
                      {{ "invoiceCredit.credits_title" | cxTranslate }}
                  </h3>
              </section>
          </section>
          <table aria-describedby="creditsTable" id="applyCreditsTable" class="credits-table table table-striped">
              <thead>
                  <tr>
                      <th scope="col" class="col-width" (click)="sortColumn($event, 'refDocNo', creditList, 'normal')">
                          {{ "invoiceCredit.credit#" | cxTranslate }}
                          <em class="my-icon fas fa-caret-down"></em>
                      </th>
                      <th scope="col" class="col-width" (click)="sortColumn($event, 'docDate', creditList, 'Date')">
                          {{ "invoiceCredit.credit_date" | cxTranslate }}
                          <em class="my-icon fas fa-caret-up"></em>
                      </th>
                      <th scope="col" class="text-align col-width"
                          (click)="sortColumn($event, 'amount', creditList, 'Number')">
                          {{ "invoiceCredit.credit_amount" | cxTranslate }}
                          <em class="my-icon fas fa-caret-down"></em>
                      </th>
                  </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let data of creditList; let i = index">
                      <td>
                        <span>
                            {{ data.refDocNo }}
                        </span>
                      </td>
                      <td>
                          {{ data.docDate }}
                      </td>
                      <td class="amount-align">({{ data.amount | localCurrency }})</td>
                  </tr>
              </tbody>
          </table>

          <section class="credits-table-2column">
              <div class="row credits-row-table" *ngFor="let data of creditList; let i = index">
                  <div class="column header-column credits-table-hcol">
                      <section class="col-header-rwd">
                          <p>
                              {{ "invoiceCredit.credit#" | cxTranslate }}
                          </p>
                          <p>
                              {{ "invoiceCredit.credit_date" | cxTranslate }}
                          </p>
                          <p>
                              {{ "invoiceCredit.credit_amount" | cxTranslate }}
                          </p>
                      </section>
                  </div>
                  <div class="column desc-column">
                      <section>
                          <p style="margin-top: 22px;">
                              <span>
                                {{ data.docNo }}
                            </span>
                          </p>
                          <p>{{ data.docDate }}</p>
                          <p>({{ data.amount | localCurrency }})</p>
                      </section>
                  </div>
              </div>
          </section>

          <button [ngClass]="
        creditItems.length != 0 && !creditItems[0].errorMsg ? '' : 'display'
      " type="button" [disabled]="!isValid" class="btn btn-primary float-right btnpaymentinvoicecredit"
              (click)="initiateCreditPaymentFlow()">
              {{ "invoiceCredit.continue_button_txt" | cxTranslate }}
          </button>
      </section>

        <!-- CREDIT LIMIT -->
        <section *ngIf="showtable == 'empty'">
            <h3 class="no-order-tittle">
                {{ "invoiceCredit.creditlimitTitle" | cxTranslate }}
            </h3>
            <section class="no-credit-invoice">
                {{ "invoiceCredit.no_credit_limit" | cxTranslate }}
            </section>
        </section>

        <section *ngIf="showtable == 'all'" class="creditlimit-section" >
            <section class="row">
                <section class="col-sm-12 col-md-12 col-md-12 col-xs-12 op_ovice">
                    <h3 class="oi-table-title">
                        {{ "invoiceCredit.creditlimitTitle" | cxTranslate }}
                    </h3>
                </section>
            </section>
            <table aria-describedby="invoicesTable" id="applyCreditsTable" class="payments-table table table-striped">
                <thead>
                    <tr>
                        <th scope="col" class="col-width">
                            {{ "invoiceCredit.date" | cxTranslate }}
                        </th>
                        <th scope="col" class="col-width">
                            {{ "invoiceCredit.CreditLimit" | cxTranslate }}
                        </th>
                        <th scope="col" class="col-width">
                            {{ "invoiceCredit.OpenCredit" | cxTranslate }}
                        </th>
                        <th scope="col" class="col-width">
                            {{ "invoiceCredit.Degreeofexhaustion" | cxTranslate }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="pay">
                            <span>
                                {{ todayDate }}
                            </span>
                        </td>
                        <td class="pay">
                            {{ creditLimit[0].creditLimit }}
                        </td>
                        <td class="pay">
                            {{ creditLimit[0].openCredit }}
                        </td>
                        <td class="pay">
                            {{ creditLimit[0].creditUsage }}
                        </td>
                    </tr>
                </tbody>
            </table>

            <section class="invoices-table-2column">
                <div class="row invoices-row-table">
                    <div class="column invoices-col-headerrwd">
                        <section class="">
                            <p scope="col">
                                {{ "invoiceCredit.date#" | cxTranslate }}
                            </p>
                            <p scope="col">
                                {{ "invoiceCredit.CreditLimit" | cxTranslate }}
                            </p>
                            <p scope="col">
                                {{ "invoiceCredit.OpenCredit" | cxTranslate }}
                            </p>
                            <p scope="col" class="">
                                {{ "invoiceCredit.Degreeofexhaustion" | cxTranslate }}
                            </p>
                        </section>
                    </div>
                    <div class="column desc-column">
                        <section>
                            <p class="pay">
                                <span>
                                    {{ todayDate }}
                              </span>
                            </p>
                            <p class="pay">{{ creditLimit[0].creditLimit }}</p>
                            <p class="pay">
                                {{ creditLimit[0].openCredit }}
                            </p>
                            <p class="pay">{{ creditLimit[0].creditUsage }}</p>
                        </section>
                    </div>
                </div>
            </section>
        </section>
        <!-- Credit limit End-->
    </section>

    <div class="line-intersection mobile-view"></div>
    <section class="mobile-view">
      <div class="banner-img">
        <app-flu-dashboard-banner
          bannerComponent="OrderHistoryBannerComponent"
        ></app-flu-dashboard-banner>
      </div>
    </section>
</section>
