<section class="custom-product-card">
    <section class="custom-card-header">
        <section>

            <a [routerLink]="['/product',productInfo.code,productInfo.code]">

                <cx-media class="cx-product-image" [container]="productInfo.images?.PRIMARY" format="product"
                    [alt]="productInfo.summary"></cx-media>
            </a>
            <a [routerLink]="['/product',productInfo.code,productInfo.code]"
                *ngIf="productInfo?.images === undefined || productInfo?.images?.length === 0">
                <img class="image-set-in-card product-img" [src]="fallbackImage" alt="">
            </a>

        </section>
        <section class="product-name">
            <a [routerLink]="['/product',productInfo.code,productInfo.code]">
                <h6>{{productInfo.name}}</h6>
            </a>
        </section>
    </section>

    <section class="custom-card-body" *ngIf=!iconDisplay>
        <p class="custom-tab-p display-type" [innerHTML]="sanitizer.bypassSecurityTrustHtml(description|slice:0:300)">
        </p>
        <a class="show-more display-type" *ngIf="description?.length>300"
            [routerLink]="['/product',productInfo.code,productInfo.code]">...Show
            More</a>
    </section>

    <section class="custom-card-body" *ngIf=iconDisplay>
        <section class="info-tabs">

            <app-my-tabs>

                <app-my-tab tabIcon="information.svg" class="product-detail">
                    <div class="padding-0">
                        <p class="custom-tab-title">{{'plp.details'| cxTranslate }}</p>
                        <p class="custom-tab-p display-type"
                          *ngIf="description !== undefined"
                            [innerHTML]="sanitizer.bypassSecurityTrustHtml(description|slice:0:300)"></p>
                        <a class="show-more display-type" *ngIf="description?.length>300"
                            [routerLink]="['/product',productInfo.code,productInfo.code]">...Show
                            More</a>
                    </div>
                </app-my-tab>

                <app-my-tab *ngIf="productInfo.batchInfo?.batchNumber" tabIcon="icn-batchexpiry.svg">
                    <div class="padding-0">
                      <p class="custom-tab-title">{{'plp.batchNumber'|cxTranslate}}</p>
                      <p class="batchinfo-p"> {{productInfo.batchInfo?.batchNumber}}</p>
                      <div *ngIf="sessionStorage.getItem('accountType') !== 'INDIRECT'">
                        <p class="custom-tab-title">{{'plp.expiryDate'|cxTranslate}}</p>
                        <p class="batchinfo-p"> {{productInfo.batchInfo?.expiryDate}}</p>
                      </div>
                      <p class="custom-tab-p">{{'plp.batchInfo'|cxTranslate}}</p>
                    </div>
                </app-my-tab>

                <app-my-tab *ngIf="productInfo?.volumePrices?.length>0 && userDetails !== undefined"
                    tabIcon="../../assets/icons/volume.png">
                    <div class="padding-0">
                        <section class="prodtabs-cont prod-promotab active">
                            <p class="custom-tab-title">{{'plp.volumeDiscounts'|cxTranslate}}</p>
                            <section class="discount-list">
                                <table aria-describedby="cartTable" class="volume-table">
                                    <thead>
                                        <th id=""></th>
                                    </thead>
                                    <tbody *ngFor="let prices of productInfo?.volumePrices; let i=index">
                                        <tr>
                                            <td>{{prices?.minQuantity}} - {{prices?.maxQuantity}}
                                                {{'plp.units'|cxTranslate}}:
                                            </td>
                                            <td>{{prices?.value | localCurrency}}</td>
                                            <td><span *ngIf="i>0"
                                                    class="save-price">{{'plp.volumePricesSave'|cxTranslate}}
                                                    {{productInfo?.volumePrices[0].value -
                                                    productInfo?.volumePrices[i].value |
                                                    currency}}</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </section>
                        </section>
                    </div>
                </app-my-tab>

                <app-my-tab *ngIf="monographLink" tabIcon="excel-sample.svg">
                    <div class="padding-0">
                        <p class="custom-tab-title">{{'plp.monograph'|cxTranslate}}</p>
                        <p class="custom-tab-p">
                            <img class="pdf-icon" src="../../assets/icons/pdf.png" alt="">
                            <a alt="" [href]="monographLink" target="_blank"
                                class="file-link blue">{{'plp.monograph'|cxTranslate}}</a>
                        </p>
                    </div>
                </app-my-tab>


                <app-my-tab *ngIf="productInfo?.productUpdates" tabIcon="warning.svg">
                    <div class="padding-0">
                        <p class="custom-tab-title">{{'plp.productUpdate'|cxTranslate}}</p>
                        <p class="custom-tab-p productupdate-p">{{productInfo?.productUpdates|slice:0:300}}</p>
                        <a class="show-more display-type" *ngIf="productInfo?.productUpdates?.length>300"
                            [routerLink]="['/product',productInfo.code,productInfo.code]">...Show
                            More</a>
                    </div>
                </app-my-tab>
            </app-my-tabs>
        </section>
    </section>
    <section class="custom-card-footer">
        <section class="prod-num">
            <strong>{{'plp.productNumber'|cxTranslate}}: {{productInfo.code}}</strong>
        </section>
        <section *ngIf="userDetails !== undefined">
            <section>
                <section *ngIf="!productInfo.flu" class="row unit-calc" [ngClass]="{'visible-hide': productInfo?.price == null || productInfo?.price == undefined || productInfo?.price?.value == 0.0}">

                    <section class="no-price" *ngIf="productInfo?.price == null || productInfo?.price == undefined || productInfo?.price?.value == 0.0">
                        <p>{{'plp.noPrice' | cxTranslate}}</p>
                    </section>

                    <section class="price-details">
                        <strong>{{'plp.yourPrice'|cxTranslate}}</strong>
                        <section class="price-total">
                            <span
                                *ngIf="productInfo?.price == undefined || productInfo?.price?.value === undefined">-</span>
                            <span *ngIf="productInfo?.price && productInfo?.price?.value !== undefined">
                                <span>{{productInfo?.price?.value | localCurrency}}</span> {{'plp.cad'|cxTranslate}}
                            </span>
                        </section>

                        <span *ngIf="productInfo?.price && productInfo?.price?.b2BUnitDisplayName !== undefined">
                          <strong>{{'plp.soldBy'|cxTranslate}}&nbsp;</strong>
                          <span>{{productInfo?.price?.b2BUnitDisplayName}}</span>
                        </span>
                    </section>

                    <section class="units" *ngIf="!productInfo?.discontinued && productInfo?.available">

                        <strong class="prodUnits">{{'plp.units'|cxTranslate}}</strong>
                        <section class="unit-area">
                            <span class="disabled " aria-hidden="true" id="{{'minusIcon'+productInfo.code}}"
                                (click)="performQuantityComputation(2,productInfo.code,productInfo.quantityPerUnit)">
                                <img src="../../assets/icons/less.svg" id="{{'unitMinus'+productInfo.code}}"
                                    class="disabled-img" alt="">
                                <img src="../../assets/icons/less-disable.svg"
                                    id="{{'unitMinusDisable'+productInfo.code}}" alt="">
                            </span>
                            <input type="number" class="prod-quantity text-center" value='1'
                                (keyup)="performQuantityComputation(null,productInfo.code,productInfo.quantityPerUnit)"
                                onkeypress="return /[0-9a-zA-Z]/i.test(event.key)"
                                id="{{'prodQuantity'+productInfo.code}}" maxlength="7" />
                            <span class="" aria-hidden="true" id="{{'plusIcon'+productInfo.code}}"
                                (click)="performQuantityComputation(1,productInfo.code,productInfo.quantityPerUnit)">
                                <img src="../../assets/icons/more.svg" id="{{'unitPlus'+productInfo.code}}" alt="">
                                <img src="../../assets/icons/more-disable.svg"
                                    id="{{'unitPlusDisable'+productInfo.code}}" class="disabled-img" alt="">
                            </span>
                        </section>
                        <section class="dosessection" id="{{'noOfDoses'+productInfo.code}}">
                            {{productInfo.quantityPerUnit}} {{'plp.doses'|cxTranslate}}</section>
                    </section>

                    <section class="product-info" *ngIf="!productInfo?.discontinued && productInfo?.available">
                        <section *ngIf="productInfo.sapAllocation">
                            <strong class="prod-allocation">{{'plp.productAllocation'|cxTranslate}}</strong>
                            <p class="allocation-p">{{'plp.allocationDetails' | cxTranslate}}
                        </section>
                    </section>
                    <button type="button" class="btn blue-button"
                        *ngIf="!productInfo?.discontinued && productInfo?.available" [ngbPopover]=popTitleSuccess
                        placement="bottom" triggers="manual" #p2="ngbPopover" popoverClass="savesuccess-msg"
                        placement="bottom" [closeDelay]="500" (click)="addToCart(productInfo?.code,p2)">
                        <em *ngIf="buttonName === 'Reserve now'" class="fa fa-calendar" aria-hidden="true"></em>
                        <span *ngIf="buttonName==='Add to cart'">{{'addToCartItems.addCart'|cxTranslate}}</span>
                        <span *ngIf="buttonName==='Login to order'">{{'addToCartItems.loginToOrder'|cxTranslate}}</span>
                        <span *ngIf="buttonName==='Reserve now'">{{'addToCartItems.reserveNow'|cxTranslate}}</span>
                    </button>
                    <ng-template #popTitleSuccess>
                        <div class="cart-value-added">
                            <img src="../../../assets/icons/confirmation-positive-green.png" class="positive-green-icon"
                                alt="success">
                            <span class="confirmation-itemsadded">{{itemAddedQuantity}}
                                {{'addToCartItems.addEntrySuccess'|cxTranslate}}</span>
                        </div>
                    </ng-template>

                </section>

                <section *ngIf="productInfo?.discontinued || !productInfo?.available">
                    <section *ngIf="productInfo?.discontinued && productInfo?.available">
                        <p class="product-discontinued-txt1">
                            {{'plp.discontinued'|cxTranslate}}</p>
                    </section>
                    <section *ngIf="productInfo?.discontinued && !productInfo?.available">
                        <p class="product-discontinued-txt1">
                            {{'plp.discontinued'|cxTranslate}}</p>
                    </section>
                    <section *ngIf="!productInfo.discontinued && !productInfo?.available" class="m0">
                        <strong class="prod-allocation"
                            *ngIf="productInfo?.availabilityText">{{productInfo?.availabilityText}}</strong>
                        <strong class="prod-allocation "
                            *ngIf="!productInfo?.availabilityText">{{'plp.productUnavailable'|cxTranslate}}</strong>
                    </section>
                </section>

                <section *ngIf="productInfo.flu">
                    <section>
                        <p *ngIf="productInfo.flu && productInfo.available && !productInfo.discontinued"
                            class="contact-info m0">
                            {{'plp.productAllocationInfo1'|cxTranslate}}<strong>
                                {{'plp.emergencycontact'|cxTranslate}}</strong>{{'plp.productAllocationInfo2'|cxTranslate}}

                        </p>
                        <button type="button" class="btn blue-button m0-spacing"
                            *ngIf="(productInfo?.price != null && productInfo?.price != undefined && productInfo?.price?.value != 0.0) && (productInfo.flu && productInfo.available && !productInfo.discontinued)"
                            (click)="addToCart(productInfo?.code,p2)">
                            <em *ngIf="buttonName === 'Reserve now'" class="fa fa-calendar" aria-hidden="true"></em>
                            <span *ngIf="buttonName==='Add to cart'">{{'addToCartItems.addCart'|cxTranslate}}</span>
                            <span
                                *ngIf="buttonName==='Login to order'">{{'addToCartItems.loginToOrder'|cxTranslate}}</span>
                            <span class="reserve-now-button"
                                *ngIf="buttonName==='Reserve now'">{{'addToCartItems.reserveNow'|cxTranslate}}</span>
                        </button>
                    </section>
                </section>

            </section>


        </section>
    </section>
    <section *ngIf="userDetails == undefined">
        <button type="button" class="btn blue-button" (click)="goToLogin()">
            <span *ngIf="buttonName==='Add to cart'">{{'addToCartItems.addCart'|cxTranslate}}</span>
            <span *ngIf="buttonName==='Login to order'">{{'addToCartItems.loginToOrder'|cxTranslate}}</span>
            <span class="reserve-now-button"
                *ngIf="buttonName==='Reserve now'">{{'addToCartItems.reserveNow'|cxTranslate}}</span>
        </button>
    </section>
</section>
