import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { translationChunksConfig } from '@spartacus/assets';
import { AuthGuard, CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { OutletPosition, provideOutlet } from '@spartacus/storefront';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { FluDashboardBannerModule } from '../shared/modules/flu-dashboard-banner/flu-dashboard-banner.module';
import { OverviewBannerModule } from '../shared/modules/overview-banner/overview-banner.module';
import { QuickOrderModule } from '../shared/modules/quick-order/quick-order.module';
import { MainPipeModule } from '../shared/pipes/mainpipe.module';
import { StatementsModule } from '../statements/statements.module';
import { AccOverviewPlaceholderComponent } from './account-overview/acc-overview-placeholder/acc-overview-placeholder.component';
import { AccOverviewPlaceholder1Component } from './account-overview/acc-overview-placeholder1/acc-overview-placeholder1.component';
import { AccountOverviewComponent } from './account-overview/account-overview.component';
import { FinancialsListComponent } from './account-overview/financials-list/financials-list.component';
import { FluContentComponent } from './account-overview/flu-content/flu-content.component';
import { ImportCartComponentAcc } from './account-overview/import-cart/import-cart.component';
import { LoaderComponent } from './account-overview/loader/loader.component';
import { NewsUpdatesComponent } from './account-overview/news-updates/news-updates.component';
import { OrderListComponent } from './account-overview/order-list/order-list.component';
import { OrderProductsComponent } from './account-overview/order-products/order-products.component';

@NgModule({
  declarations: [AccountOverviewComponent, OrderListComponent, FinancialsListComponent, LoaderComponent, NewsUpdatesComponent, OrderProductsComponent, FluContentComponent, AccOverviewPlaceholderComponent, AccOverviewPlaceholder1Component, ImportCartComponentAcc],
  imports: [
    CommonModule,
    I18nModule,
    OverviewBannerModule,
    FluDashboardBannerModule,
    RouterModule,
    SlickCarouselModule,
    StatementsModule,
    QuickOrderModule,
    NgbModule,
    MainPipeModule,
    ConfigModule.withConfig({
      cmsComponents: {
        AccountOverviewFinancialsComponent: {
          component: FinancialsListComponent,
          guards: [AuthGuard],
        },
        AccountOverviewOrderProductsComponent: {
          component: OrderProductsComponent,
          guards: [AuthGuard],
        }
      },
      i18n: {
        backend: { loadPath: 'assets/i18n-assets/{{lng}}/{{ns}}.json' },
      },
      chunks: translationChunksConfig,
    } as CmsConfig),
  ],
  providers: [
    provideOutlet({
      id: 'AccountOverviewPlaceholderSlot',
      position: OutletPosition.REPLACE,
      component: AccOverviewPlaceholderComponent,
    }),
    provideOutlet({
      id: 'AccountOverviewPlaceholderSlot2',
      position: OutletPosition.BEFORE,
      component: AccOverviewPlaceholder1Component,
    }),
    provideOutlet({
      id: 'AccountOverviewOrdersSlot',
      position: OutletPosition.REPLACE,
      component: AccountOverviewComponent,
    }),
    provideOutlet({
      id: 'importCartPositionForAcc',
      position: OutletPosition.REPLACE,
      component: ImportCartComponentAcc,
    })
  ]
},
)
export class AccountOverviewModule { }
