<section class="col-12 col-xs-12 col-sm-7 col-md-8 left-section">
  <div class="ad-acc-details displayIphone">
    <div class="titletext1" role="heading" aria-level="2">
      {{ "accountOverview.myAccount_label" | cxTranslate }}
    </div>
    <div class="row acc-numb">
      <div class="col-8 col-xs-8 col-md-8 acc-title">
        <img src="/../../../assets/icons/user-account.svg" alt="user account" />
        <span
          >{{ "accountOverview.account_label" | cxTranslate }} &nbsp;
          {{ accountNumber }}</span
        >
      </div>
      <div class="col-4 col-xs-4 col-md-4 acc-title-manage">
        <a
          (click)="navigateToAccountDetails()"
          data-di-id="di-id-9b8eeaed-3b366785"
          >{{ "accountOverview.view_label" | cxTranslate }}</a
        >
      </div>
    </div>
  </div>
  <app-order-list></app-order-list>
</section>
